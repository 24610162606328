<template>
<v-layout justify="space-around" :style="{'margin-top': '65px'}">
    <v-flex xs12 sm12 md2 offset-md5>
      <v-card >
        <v-card-title primary-title style="justify-content:center">
          <div>
            <h3 :style="{'font-size':'20px', 'font-weight':'500'}" class="mb-0">Alterar Senha</h3>
          </div>
        </v-card-title>
        <v-text-field
          class="input-margin"
          label="Senha Atual"
          type="password"
          v-model="currentPass"
          data-cy="currentPass"
        ></v-text-field>
        <br>
        <v-text-field
          class="input-margin"
          label="Nova Senha"
          type="password"
          v-model="newPassword"
          data-cy="newPassword"
        ></v-text-field>
        <br>
        <v-text-field
          class="input-margin"
          v-on:keyup.enter="changePassword"
          label="Confirmação de Senha"
          type="password"
          v-model="aggreePassword"
          data-cy="aggreePassword"
        ></v-text-field>
        <br>
        <v-btn
          style=" margin-bottom: 10px;"
          dark
          color="primary"
          data-cy="btn-changePass"
          @click="changePassword">
          Salvar
        </v-btn>
      </v-card>
    </v-flex>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :color="snackbarType"
      :top="true"
      :auto-height="true"
      :vertical="true"
    >
      {{snackbarMessage}}
    </v-snackbar>
  </v-layout>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService';
import UserService from '@/services/UserService';

export default {
  data: () => ({
    currentPass: '',
    newPassword: '',
    aggreePassword: '',
    userLogged: undefined,
    snackbar: false,
    snackbarMessage: '',
    snackbarType: ''
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      let self = this;
      await UserService.index({_id: this.$store.state.user._id}).then(function(data) {
        self.userLogged = data.data[0];
      });
    },

    async changePassword() {
      let self = this;
      self.userLogged.password = self.newPassword;
      const response = await AuthenticationService.comparePassword({
        email: self.userLogged.email,
        password: self.currentPass
      });
      if (response.data === false) {
        self.snackbar = true;
        self.snackbarMessage = 'Senha atual inválida!';
        self.snackbarType = 'error';
      } else if (this.newPassword !== this.aggreePassword) {
        self.snackbar = true;
        self.snackbarMessage = 'Nova senha e confirmação de senha estão diferentes!';
        self.snackbarType = 'warning';
      } else if (!this.newPassword || !this.aggreePassword) {
        self.snackbar = true;
        self.snackbarMessage = 'Nova senha e confirmação de senha devem ser informados!';
        self.snackbarType = 'warning';
      } else {
        await UserService.update(this.userLogged).then(function() {
          self.newPassword = '';
          self.currentPass = '';
          self.aggreePassword = '';
          self.snackbar = true;
          self.snackbarMessage = 'Senha alterada com sucesso!';
          self.snackbarType = 'success';
        });
      }
    }
  }
};
</script>

<style>
.input-margin {
  margin: 0px 10px 0px 10px;
}

</style>
