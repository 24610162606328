<template>
  <v-container grid-list-lg style="padding: 0; margin: 0; max-width: 100%">
    <v-layout row wrap class="workouts-list">
      <v-flex xs6 sm6 md4 lg3 v-for="workoutInProgress in workoutsInProgress" :key="workoutInProgress.id" style="padding: 3px; height: 100%" class="treino">
        <v-card @click.native="dialog = true; workoutSelected = workoutInProgress">
          <v-card-title row wrap>
            <h3 data-cy="user-progresso"><span class="fa fa-birthday-cake" v-if="birthdaysUsers.indexOf(workoutInProgress.user[0]._id) > -1" style="color: #FFA300"></span>
            {{ workoutInProgress.user[0].name }}</h3>
            <v-spacer></v-spacer>
            <div style="display: flex; position: absolute; right: -20px; top: 0;">
              <div class="info-time">
                <i class="fa fa-clock" :style="{'writing-mode': 'vertical-lr'}"></i> {{ formatarData(workoutInProgress.executions.startedAt) }}
                <!-- <span style="width:100%; clear: both; text-align: left">Início: </span> -->
              </div>
              <!-- <div class="info-time"> -->
                <!-- <i class="fa fa-stopwatch"></i> {{ calcularTermino(workoutInProgress.executions) }} -->
                <!-- <span style="width:100%; clear: both; text-align: left">Início: </span> -->
              <!-- </div> -->
            </div>
          </v-card-title>
          <v-card-text>
            <table style="width: 100%; font-size: 12px">
              <tr>
                  <th align="left">Exercício</th>
                  <th width="40" style="min-width: 40px">Reps</th>
                  <th width="40" style="min-width: 40px">Séries</th>
              </tr>
              <tr :style="{'font-weight': 'bold'}" v-for="(exercise, index) in workoutInProgress.exercises" :key="index" :class="Math.floor(exercise.order) % 2 == 0 ? 'dark' : 'light'">
                <td  class="exercise-cell" align="left">{{ exercise.exercise.name }}</td>
                <td>{{ exercise.reps }}</td>
                <td :rowspan="calculateRowSpan(index, workoutInProgress.exercises)" v-if="!workoutInProgress.exercises[index-1] || (Math.floor(workoutInProgress.exercises[index-1].order) !== Math.floor(exercise.order))">{{ exercise.series }}</td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title :style="{'font-size':'24px', 'font-weight':'500'}">Finalizar o treino?</v-card-title>
        <!-- <v-card-text>
          Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
        </v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false; terminateWorkout(workoutSelected)"
          >
            Finalizar
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import WorkoutService from '@/services/WorkoutService';
import UserService from '@/services/UserService';
// import ExerciseService from '@/services/ExerciseService';
const moment = require('moment');

export default {
  data: () => ({
    workoutsInProgress: [],
    exercises: [],
    workoutSelected: undefined,
    dialog: false,
    birthdaysUsers: []
  }),
  mounted: async function() {
    this.initialize();
    let instance = this;
    this.birthdaysUsers = [];
    let birthdays = await UserService.getBirthdays('today');
    if (birthdays && birthdays.data) {
      for (let b of birthdays.data) {
        this.birthdaysUsers.push(b._id);
      }
    }
    setInterval(function() {
      instance.initialize();
    }, 5000);
  },
  methods: {
    initialize: async function() {
      this.exercises = [];
      let workoutsInProgress = [];
      // let exercisesReturn = await ExerciseService.index();
      // let exercises = exercisesReturn.data;
      // for (let ex of exercises) {
      //   let exercise = {
      //     text: ex.name,
      //     value: ex.id
      //   };
      //   this.exercises.push(exercise);
      // }
      this.$root.exibirBarraTopo = false;
      let workouts = await WorkoutService.listInProgress();
      for (let workout of workouts) {
        if (workout.exercises) {
          workout.exercises = await this.orderedExercises(workout.exercises);
        }
        workoutsInProgress.push(workout);
      }
      this.workoutsInProgress = workoutsInProgress;
    },
    orderedExercises: async function(exercises) {
      exercises = await exercises.sort(async function(a, b) {
        return a.order < b.order ? -1 : 1;
      });
      return exercises;
    },
    recuperarNomeExercicio: function(exerciseId) {
      if (exerciseId > -1) {
        let exercicio = this.exercises.filter(ex => ex.value === exerciseId)[0];
        return exercicio.text;
      } else {
        return undefined;
      }
    },
    calculateRowSpan(index, workoutExercises) {
      let wExercise = workoutExercises[index];
      let rowSpan = 0;
      for (let idx in workoutExercises) {
        if (idx !== index) {
          let exerciseLoop = workoutExercises[idx];
          if (exerciseLoop.order && wExercise.order && Math.floor(exerciseLoop.order) === Math.floor(wExercise.order)) {
            rowSpan++;
          }
        }
      }
      return rowSpan;
    },
    formatarData: function(dateStr) {
      return moment(dateStr).format('HH:mm');
    },
    terminateWorkout: async function(workoutSelected) {
      let idWorkout = workoutSelected._id;
      let idExecution = workoutSelected.executions._id;
      await WorkoutService.terminateWorkoutInProgress(idWorkout, idExecution);
      this.initialize();
    },
    calcularTermino: function(workout) {
      let duration = workout.duration;
      let horaAtual = moment(new Date());
      let horaInicio = moment(workout.startedAt);
      let horaTermino = horaInicio.add(duration, 'm');
      let diff = horaTermino.diff(horaAtual, 'minutes');
      return diff;
    }
  }
};
</script>
