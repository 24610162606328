<template>
  <v-app-bar dark app class="header" :extension-height="20" :dense="true">
    <v-btn data-cy="btn-menu" icon v-if="$root.exibirNavBar" @click.stop="$root.drawer = !$root.drawer"><v-icon>fa-bars</v-icon></v-btn>

    <v-toolbar-title class="headername">FITZONE</v-toolbar-title>
    <!--<div>
     <v-img class="image" :src="require('@/assets/icon-192x192.png')" height="47px" width="50px"/>
    </div> -->

    <v-spacer></v-spacer>

    <!-- <v-btn icon>
      <v-icon>fa-search</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>fa-sync</v-icon>
    </v-btn> -->

    <!-- <v-btn icon>
      <v-icon>fa-sync</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>fa-refresh</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
export default {
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  .header {
    background: linear-gradient(to right, #1e469a, #49a7c1);
    color: #F9E4D5;
    font-family: 'Roboto';
  }
  .headername {
    color: #F9E4D5;
    font-family: 'Roboto';
  }
  .image {
    display: flex;
    justify-content: flex-end;
    padding-left: 30px;
    margin: 5px;
}
</style>
