import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/components/Login';
import CrudClient from '@/components/client/CrudClient';
import CrudExercise from '@/components/exercise/CrudExercise';
import CrudWorkout from '@/components/workout/CrudWorkout';
import InProgress from '@/components/workout/InProgress';
import Home from '@/components/Home';
import CheckIn from '@/components/workout/CheckIn';
import UserCheckin from '@/components/workout/UserCheckin';
import WorkoutReport from '@/components/workout/WorkoutReport';
import MyWorkoutsCheckin from '@/components/workout/MyWorkoutsCheckin';
import MyWorkouts from '@/components/workout/MyWorkouts';
import UserHome from '@/components/UserHome';
import ChangePassword from '@/components/ChangePassword';
import ClassSchedule from '@/components/classes/ClassSchedule';
import Sport from '@/components/Sport';
import CustomerPlains from '@/components/CustomerPlains';
import store from '@/store/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/client',
    name: 'Clientes',
    component: CrudClient,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/exercicio',
    name: 'Exercícios',
    component: CrudExercise,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/treino',
    name: 'Treinos',
    component: CrudWorkout,
    beforeEnter(to, from, next) {
      console.log(store);
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/meustreinos',
    name: 'Meus Treinos',
    component: MyWorkouts,
    beforeEnter(to, from, next) {
      let user = from.query.user;
      let userLogged = store.state.user._id;
      let isUserlogged = user === userLogged || user === undefined;
      if (store.state.user === null || isAdmin() || !isUserlogged) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/checkin',
    name: 'Checkins',
    component: CheckIn,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/usercheckin',
    name: 'Checkin do Usuário',
    component: UserCheckin,
    beforeEnter(to, from, next) {
      if (store.state.user === null || isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/myworkoutscheckin',
    name: 'Checkin Meus Treinos',
    component: MyWorkoutsCheckin
  },
  {
    path: '/inprogress',
    name: 'Treinos em Andamento',
    component: InProgress,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/plan',
    name: 'Planos',
    component: CustomerPlains,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/home',
    name: 'Início',
    component: Home,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/userhome',
    name: 'Home do Usuário',
    component: UserHome,
    beforeEnter(to, from, next) {
      if (store.state.user === null || isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/class',
    name: 'Aulas',
    component: ClassSchedule,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/sport',
    name: 'Modalidades',
    component: Sport,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/report',
    name: 'Relatórios',
    component: WorkoutReport,
    beforeEnter(to, from, next) {
      if (store.state.user === null || !isAdmin()) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/changepassword',
    name: 'Alterar Senha',
    component: ChangePassword
  }
];

const router = new VueRouter({
  routes,
});

function isAdmin() {
  let isAdmin = false;
  let user = store.state.user;
  if (user.profile === 'ADMIN') {
    isAdmin = true;
  }
  return isAdmin;
}

export default router;
