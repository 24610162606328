<template>
  <div>
    <v-layout row wrap justify-center :style="{'background-color': 'white', 'border-bottom': '2px solid rgba(0,0,0,.12)', 'margin-left':'0px', 'margin-right':'0px'}" >
      <div style="font-size:20px; align-self:center; margin-left:10px">Clientes</div>
      <v-divider
        class="mx-2 divider"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        :class="classTextField"
        v-model="search"
        append-icon="fa-search"
        label="Localizar"
        hide-details
        data-cy="input-localizar"
      ></v-text-field>
      <div :style="{'padding-bottom': '10px', 'padding-right': '10px', 'padding-left': '20px'}">
        <v-checkbox
          v-model="checkboxInativos"
          @change="listarUsuariosInativos()"
          label="Listar Clientes Inativos"
          color="primary"
          :value="checkboxInativos"
          hide-details
          data-cy="listar-inativos"
        ></v-checkbox>
      </div>
      <v-dialog v-model='dialog' max-width="1000px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn data-cy="btn-cliente" raised color="primary" v-bind="attrs" v-on="on" style="margin-top: 10px" @click="focusName();">Novo cliente</v-btn>
        </template>
        <v-card>
          <v-form ref="form">
          <v-card-title>
            <span :style="{'font-size':'24px', 'font-weight':'500'}">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md7>
                  <v-text-field data-cy="nome" ref="nomeCliente" v-model='editedItem.name' label="Nome" :rules="[rules.required]"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-select
                    v-model="editedItem.profile"
                    :items="profileOptions"
                    label="Perfis"
                    :rules="[rules.required]"
                    data-cy="profile"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md2>
                  <v-select
                    v-model="editedItem.status"
                    :items="statusOptions"
                    label="Situação"
                    data-cy="situacao"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field data-cy="cpf" ref="cpf" v-model='editedItem.id' label="CPF" :rules="[rules.required]" ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field data-cy="apelido" v-model='editedItem.nickName' label="Apelido"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field data-cy="celular" v-model='editedItem.phone' label="Celular"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field data-cy="email" v-model='editedItem.email' label="E-mail" :rules="[rules.required]"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field data-cy="senha" :append-icon="showPass ? 'fa-eye' : 'fa-eye-slash'" :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass" v-model='editedItem.password' label="Senha" ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field data-cy="confirmacaoSenha" :append-icon="showPassAgree ? 'fa-eye' : 'fa-eye-slash'" :type="showPassAgree ? 'text' : 'password'" @click:append="showPassAgree = !showPassAgree" v-model='agreePass' label="Confirmação de senha" ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select
                    v-model="editedItem.gender"
                    :items="genderOptions"
                    label="Gênero"
                    data-cy="genero"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md2>
                 <v-text-field data-cy="data-nasc" :return-masked-value="true" :value="editedItem.birthDate && formatToShow(editedItem.birthDate)" @change="editedItem.birthDate = formatDate($event)" :mask="'##/##/####'" label="Data Nascimento"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md2>
                  <v-text-field data-cy="cep" v-model='editedItem.cep' v-on:keydown.tab="preencherEnderecoPeloCep(editedItem.cep)" label="CEP"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md1 :style="{'align-self': 'center'}">
                    <v-progress-circular
                    :size="20"
                    :width="3"
                    v-if="loading"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn v-else icon @click="preencherEnderecoPeloCep(editedItem.cep)">
                    <v-icon color="blue darken-1">fa-search</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 md7>
                  <v-text-field data-cy="rua" ref='street' v-model='editedItem.street' label="Endereço"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md2>
                  <v-text-field data-cy="numero" ref='number' v-model='editedItem.number' label="Número"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field data-cy="complemento" v-model='editedItem.complement' label="Complemento"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field data-cy="bairro" ref= 'neighborhood' v-model='editedItem.neighborhood' label="Bairro"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field data-cy="cidade" ref='city' v-model='editedItem.city' label="Cidade"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md12>
                  <v-text-field data-cy="profissao" v-model='editedItem.job' label="Profissão"></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-card outlined elevation="0">
                    <v-card-title style="color:cadetblue">
                      Planos do Cliente
                    </v-card-title>
                    <v-layout row v-for="(plan, index) in customerPlans" :key="plan._id">
                      <v-flex xs12 sm6 md5>
                        <v-select
                          style="margin-left:15px"
                          v-model="plan.plan"
                          :items="plans"
                          label="Plano"
                          data-cy="plano"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 md2>
                        <v-text-field
                          v-mask="'##/##/####'"
                          label="Data Inicial do Plano"
                          data-cy="paymentInstallments"
                          v-model="plan.startDate"
                          @blur="calculateEndDate(plan.startDate, index)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md2>
                        <v-text-field
                          v-mask="'##/##/####'"
                          label="Data Final do Plano"
                          data-cy="paymentInstallments"
                          v-model="plan.endDate"
                          disabled
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md1>
                        <v-checkbox
                          v-model="plan.inactive"
                          @change="(val) => plan.inactive === val"
                          label="Inativo"
                          color="primary"
                          :value="checkboxInativos"
                          hide-details
                          data-cy="listar-inativos"
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex xs12 sm4 md2>
                        <v-btn
                          elevation="2"
                          icon
                          medium
                          tile
                          @click="addPlan"
                          title="Adicionar plano"
                          style="margin: 10px 0 0 5px; min-width:0px;color:green">
                          <v-icon style="font-size: 12px">fa-plus</v-icon>
                        </v-btn>
                        <v-btn
                          elevation="2"
                          icon
                          medium
                          tile
                          title="Excluir plano"
                          style="margin: 10px 0 0 5px; min-width:0px; color:red"
                          @click="removePlan(index)">
                          <v-icon style="font-size: 12px">far fa-trash-alt</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="btn-cancelar" color="red darken-1" text @click.native="dialog = false">Cancelar</v-btn>
            <v-btn data-cy="btn-salvar" color="green darken-1" text @click.native="save">Salvar</v-btn>
          </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :color="snackbarType"
      :top="true"
      :vertical="true"
      >
      {{snackbarMessage}}
    </v-snackbar>
    <v-data-table
      :headers='headers'
      :items='users'
      :search='search'
      class="elevation-1"
      :options.sync="pagination"
      :footer-props="{
        'items-per-page-text': 'Registros por página'
      }"
    >
     <template v-slot:[`item.statusPlan`]="{ item }">
        <v-icon :style="{ 'fontSize': '14px', 'color': item.statusPlan === true ? 'green' : 'red' }">fa-circle</v-icon>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon :style="{ 'fontSize': '14px', 'color': item.status === 'ACTIVE' ? 'green' : 'red' }">fa-circle</v-icon>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <td class="justify-center layout px-0">
          <v-icon
            small
            class="mr-2"
            title="Treinos"
            @click="abrirTreinos(item)"
            data-cy="abrir-treino"
          >
            fa-dumbbell
          </v-icon>
          <v-icon
            small
            title="Editar"
            @click="editItem(item)"
            data-cy="editar-cliente"
          >
            fa-edit
          </v-icon>
        </td>
      </template>
      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import UserService from '@/services/UserService';
import CustomerPlansService from '@/services/CustomerPlansService';
import SportService from '@/services/SportService';
const moment = require('moment');
export default {
  data: () => ({
    classTextField: 'textfield',
    search: '',
    checkboxInativos: false,
    cepInvalido: false,
    loading: false,
    usuariosAtivosEInativos: [],
    users: [],
    sports: [],
    plans: [],
    customerPlans: [],
    dialog: false,
    snackbarMessage: '',
    snackbar: false,
    snackbarType: '',
    error: '',
    showPass: false,
    showPassAgree: false,
    agreePass: '',
    invalidDatePlan: false,
    profileOptions: [
      {
        text: 'Aluno',
        value: 'USER'
      },
      {
        text: 'Administrador',
        value: 'ADMIN'
      }
    ],
    statusOptions: [
      {
        text: 'Ativo',
        value: 'ACTIVE'
      },
      {
        text: 'Inativo',
        value: 'INACTIVE'
      }
    ],
    genderOptions: [
      {
        text: 'Masculino',
        value: 'M'
      },
      {
        text: 'Feminino',
        value: 'F'
      }
    ],
    headers: [
      {
        text: 'Código',
        align: 'center',
        value: 'id'
      },
      {
        text: 'Nome',
        align: 'center',
        value: 'name'
      },
      { text: 'E-mail', value: 'email', align: 'center' },
      { text: 'Cliente Ativo', value: 'status', align: 'center' },
      { text: 'Plano Cliente Ativo', value: 'statusPlan', align: 'center' },
      { text: 'Ações', value: 'action', sortable: false, align: 'center' }
    ],
    editedIndex: -1,
    editedItem: {status: 'ACTIVE', profile: 'USER'},
    defaultItem: {status: 'ACTIVE', profile: 'USER'},
    defaultItemPlan: {resumePlan: '', startDate: '', endDate: ''},
    rules: {
      required: value => !!value || 'Required.'
    },
    pagination: {
      rowsPerPage: 10
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Cliente' : 'Editar Cliente';
    },
    computedDateStart(date) {
      return date ? moment(date).format('DD/MM/YYYY') : '';
    }
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.initialize();
      }
    }
  },

  async created() {
    await this.initialize();
    this.$root.exibirBarraTopo = true;
  },

  methods: {
    async initialize() {
      let self = this;
      this.editedItem = Object.assign({}, this.defaultItem);
      self.customerPlans = [];
      await SportService.index().then(function(data) {
        let sports = data.data;
        for (let s of sports) {
          let itemComboBox = {
            text: s.name,
            value: s._id
          };
          self.sports.push(itemComboBox);
        }
      });
      await CustomerPlansService.index().then(function(data) {
        let plans = data.data;
        for (let p of plans) {
          for (let s of self.sports) {
            let resumePlan = '';
            if (s.value === p.sport) {
              resumePlan = `${s.text}/ Vigência: ${p.validDays} dias / ${p.daysWeek} X semana`;
              let itemComboBox = {
                text: resumePlan,
                value: p._id,
                plan: p._id,
                validDays: p.validDays
              };
              self.plans.push(itemComboBox);
            }
          }
        }
      });
      await UserService.index().then(function(data) {
        self.users = data.data;
        self.usuariosAtivosEInativos = data.data;
        self.users.forEach(user => {
          let statusPlan = false;
          if (user.plans === undefined || user.plans.length === 0) {
            statusPlan = false;
          } else {
            statusPlan = false;
            user.plans.forEach(plan => {
              if (plan.inactive === undefined || plan.inactive === false) {
                statusPlan = true;
              }
            });
          }
          Object.assign(user, {statusPlan: statusPlan});
        });
      });
      this.listarUsuariosInativos();
      this.addPlan();
    },

    listarUsuariosInativos() {
      let self = this;
      let usuariosAtivos = [];
      if (!self.checkboxInativos) {
        self.users.forEach(function (usuario) {
          if (usuario.status === 'ACTIVE') {
            usuariosAtivos.push(usuario);
          }
        });
        self.users = usuariosAtivos;
      } else {
        self.users = self.usuariosAtivosEInativos;
      }
    },
    addPlan() {
      if (!this.customerPlans || this.customerPlans === 0) {
        this.customerPlans = [];
      }
      this.customerPlans.push(
        Object.assign({}, this.defaultItemPlan)
      );
    },
    removePlan(index) {
      this.customerPlans.splice(index, 1);
      if (this.customerPlans.length === 0) {
        this.editedItem.plans = [];
        this.addPlan();
      }
    },
    calculateEndDate(date, index) {
      let plan = this.customerPlans[index].plan;
      let totalDays = 0;
      let endDate = new Date();
      this.plans.forEach(p => {
        if (p.value === plan) {
          totalDays = p.validDays;
        }
      });
      endDate = moment(date, 'DD-MM-YYYY').add(totalDays, 'd');
      this.customerPlans[index].endDate = this.formatToShow(endDate);
    },
    editItem(item) {
      this.customerPlans = [];
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.plans.length > 0) {
        this.editedItem.plans.forEach(p => {
          this.customerPlans.push(p);
        });
        this.customerPlans.forEach(c => {
          c.startDate = this.formatToShow(c.startDate);
          c.endDate = this.formatToShow(c.endDate);
          this.plans.forEach(p => {
            if (p.value === c.plan) {
              Object.assign(c, {resumePlan: p.resumePlan});
            }
          });
        });
      } else {
        this.customerPlans.push(Object.assign({}, this.defaultItemPlan));
      }
      this.dialog = true;
    },

    abrirTreinos(user) {
      this.$router.push({ path: '/treino', query: { user: user._id } });
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.agreePass = '';
      this.editedItem.password = '';
    },

    validateOnlyDigit(value) {
      if (value !== undefined) {
        let isnum = /^\d+$/.test(value);
        if (!isnum) {
          this.snackbar = true;
          this.snackbarMessage = 'Informe apenas dígitos no campo Código/RG/CPF/!';
          this.snackbarType = 'warning';
          this.$refs.cpf.focus();
          return false;
        }
        return true;
      }
      return true;
    },
    async save() {
      let hasPlan = false;
      this.invalidDatePlan = false;
      if (this.customerPlans.length > 0) {
        this.customerPlans.forEach(c => {
          if (c.plan !== undefined && (c.startDate === '' || c.startDate === undefined)) {
            this.invalidDatePlan = true;
          }
          if (c.plan === undefined && (c.startDate !== '' && c.startDate !== undefined)) {
            this.invalidDatePlan = true;
          }
          if (c.plan === undefined && (c.startDate === '' || c.startDate === undefined)) {
            let index = this.customerPlans.indexOf(c);
            this.customerPlans.splice(index, 1);
          }
          if (c.plan !== undefined) {
            hasPlan = true;
          }
        });
      }
      if (hasPlan === true) {
        this.editedItem.plans = this.customerPlans;
      }
      if (this.validateBeforeSave()) {
        let self = this;
        if (this.editedItem.plans === undefined) {
          this.editedItem.plans = [];
        }
        self.snackbarMessage = '';
        self.snackbarType = '';
        for (let p of this.editedItem.plans) {
          p.startDate = moment(p.startDate, 'DD-MM-YYYY').format();
          p.endDate = moment(p.endDate, 'DD-MM-YYYY').format();
        }
        if (this.editedIndex > -1) {
          self.snackbar = true;
          try {
            await UserService.update(this.editedItem).then(function() {
              self.editedItem.password = '';
              Object.assign(self.users[self.editedIndex], self.editedItem);
              self.close();
              self.snackbarMessage = `Usuário alterado com sucesso!`;
              self.snackbarType = 'success';
              self.customerPlans = [];
            });
          } catch (error) {
            self.error = error.response.data.error.errmsg;
            if (self.error !== undefined && self.error.includes('duplicate key')) {
              self.snackbarMessage = `O CPF informado já existe!`;
              self.snackbarType = 'warning';
            } else {
              self.snackbarMessage = 'Informe todos os campos obrigatórios.';
              self.snackbarType = 'info';
            }
          }
        } else {
          self.snackbar = true;
          try {
            await UserService.create(this.editedItem).then(function(data) {
              self.users.push(data.data);
              self.snackbarMessage = `Usuário cadastrado com sucesso!`;
              self.snackbarType = 'success';
              self.close();
              self.customerPlans = [];
            });
          } catch (error) {
            self.error = error.response.data.error.errmsg;
            if (self.error !== undefined && self.error.includes('duplicate key')) {
              self.snackbarMessage = `O Código/RG/CPF/ informado já existe!`;
              self.snackbarType = 'warning';
            } else {
              self.snackbarMessage = error;
              self.snackbarType = 'info';
            }
          }
        }
      }
    },
    validateBeforeSave() {
      let validate = false;
      if (this.invalidDatePlan === true) {
        this.snackbar = true;
        this.snackbarMessage = `Selecione um plano e informe uma data início.`;
        this.snackbarType = 'warning';
        validate = false;
        return;
      }
      if (this.editedItem.password && this.editedItem.password !== this.agreePass) {
        this.snackbar = true;
        this.snackbarMessage = `Campo senha e confirmação de senha estão diferentes.`;
        this.snackbarType = 'warning';
        validate = false;
      } else {
        validate = true;
      }
      return validate;
    },

    formatDate: function(dateStr) {
      return moment(dateStr, 'DD/MM/YYYY');
    },

    formatToShow: function(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    focusName() {
      setTimeout(() => {
        this.$refs.nomeCliente.focus();
      }, 100);
    },
    preencherEnderecoPeloCep: function(cep) {
      this.loading = true;
      var endereco = this.buscarEnderecoPeloCep('https://viacep.com.br/ws/' + cep + '/json/');
      if (endereco !== null && !endereco.erro) {
        setTimeout(() => {
          this.cepInvalido = false;
          this.editedItem.street = endereco.logradouro;
          this.editedItem.neighborhood = endereco.bairro;
          this.editedItem.city = endereco.localidade;
          this.editedItem = JSON.parse(JSON.stringify(this.editedItem));
          this.$nextTick(function () {
            this.$refs.number.focus();
            this.loading = false;
          });
        }, 500);
      } else {
        this.cepInvalido = true;
        this.editedItem.street = undefined;
        this.editedItem.neighborhood = undefined;
        this.editedItem.city = undefined;
        this.loading = false;
        this.snackbar = true;
        this.snackbarMessage = "Cep não encontrado."
        this.snackbarType = "warning";
      }
    },
    buscarEnderecoPeloCep(url) {
      try {
        var req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.send(null);
        var json = JSON.parse(req.responseText);
        return json;
      } catch (err) {
        console.log(`O cep informado é invalido: ${err}`);
      }
      return null;
    }
  }
};
</script>
<style>
  .textfield {
    padding-right: 30px;
    padding-bottom: 5px;
  }
  @media (max-width: 600px) {
    .textfield {
      padding-right: 0px;
      padding-bottom: 5px;
    }
  }
  @media (max-width: 600px) {
    .divider {
      padding-right: 50px;
    }
  }

</style>
