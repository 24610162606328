<template>
  <v-layout row wrap justify-center>
    <v-flex sm4 xs12>
      <h1>Relatório de Treinos</h1>
      <v-container grid-list-md >
        <v-layout row wrap justify-space-around >
          <v-text-field
            ref="inputStartDate"
            v-model="inputStartDate"
            :value="computedDateStart"
            label="Data Inicial"
            append-icon="fa-calendar"
            @click:append="getDate(true)"
            @blur="inputDate(true)"
            data-cy="data-inicial"
            :class="classTextField"
            :style="{'width':'150px'}"
          ></v-text-field>
          <v-text-field
            v-model="inputEndDate"
            ref="inputEndDate"
            :value="computedDateEnd"
            label="Data Final"
            append-icon="fa-calendar"
            @click:append="getDate(false)"
            @blur="inputDate(false)"
            data-cy="data-final"
            :style="{'width':'150px'}"
          ></v-text-field>
          <v-checkbox
          v-model="isGroup"
          label="Agrupar por cliente"
          color="primary"
          :value="isGroup"
          hide-details
          @change="groupWorkoutsByUser()"
          :style="{'margin-left': '30px', 'max-inline-size': 'fit-content', 'margin-bottom': '20px'}"
          data-cy="agrupar"
          ></v-checkbox>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            width="290px"
          >
            <v-date-picker v-model="date" scrollable locale="pt-br" no-title @input="setDate">
            </v-date-picker>
          </v-dialog>
        </v-layout>
          <v-combobox
            :items="users"
            :item-text="(user) => user.name"
            :item-value="(user) => user.id"
            v-model="selectedUsers"
            chips
            multiple
            hide-selected
            return-object
            solo
            small-chips
            :allow-overflow="false"
            deletable-chips
            label="Selecione o(s) aluno(s) ou deixe em branco para buscar todos"
            data-cy="usuarios"
          ></v-combobox>
          <v-btn data-cy="executar" raised color="primary" @click="generate">Executar</v-btn>
      </v-container>
    </v-flex>
    <v-flex xs12 sm12>
      <v-data-table
        :headers="headers"
        :items="searchResults"
        v-if="searchResults && searchResults.length && !isGroup"
        class="elevation-1"
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-text':'Registros por página'
        }"
        >
       <template v-slot:[`item.date`]="{ item }">
          {{ formatarData(item.date) }}
       </template>
        <template v-slot:[`item.userName[0]`]="{ item }">
          {{ item.userName[0] }}
        </template>
        <template v-slot:[`item.workout`]="{ item }">
          {{ item.workout }}
        </template>
        <template v-slot:footer >
            <div :style="{'font-size':'1.5rem', 'margin-top': '10px', 'text-align': 'center'}">
              <span><b>Total de Checkins: </b></span>
              <span> {{countCheckins}} </span>
            </div>
        </template>
      </v-data-table>
      <v-data-table
        :headers="headersGroup"
        :items="searchResults"
        v-else-if="searchResults && searchResults.length && isGroup"
        class="elevation-1"
        :pagination.sync="paginationGroup"
        rows-per-page-text="Registros por página"
        >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.count`]="{ item }">
          {{ item.count }}
        </template>
          <template v-slot:footer >
            <p :style="{'font-size':'1.5rem', 'margin-top': '10px', 'justify-content': 'center'}">
              <span><b>Total de Checkins:</b></span>
              <span>{{countCheckins}}</span>
            </p>
        </template>
      </v-data-table>
       <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTime"
        :color="snackbarType"
        :top="true"
      >
        {{snackbarMessage}}
      </v-snackbar>
     </v-flex>
  </v-layout>
</template>

<script>
import WorkoutService from '@/services/WorkoutService';
// import ExerciseService from '@/services/ExerciseService';
import UserService from '@/services/UserService';
const moment = require('moment');

export default {
  data: () => ({
    countCheckins: 0,
    isGroup: false,
    searchInput: '',
    inputStartDate: undefined,
    inputEndDate: undefined,
    date: new Date().toISOString().substr(0, 10),
    startDate: undefined,
    endDate: undefined,
    user: undefined,
    editingStartDate: true,
    modal: false,
    users: [],
    selectedUsers: [],
    search: '',
    searchResults: undefined,
    snackbar: false,
    snackbarTime: 3000,
    snackbarType: '',
    snackbarMessage: '',
    classTextField: 'classtextfield',
    dataPicker: false,
    headers: [
      {
        text: 'Aluno',
        align: 'center',
        sortable: true,
        value: 'userName[0]'
      },
      {
        text: 'Data',
        align: 'center',
        sortable: true,
        value: 'date'
      },
      {
        text: 'Treino',
        align: 'center',
        sortable: false,
        value: 'workout'
      }
    ],
    headersGroup: [
      {
        text: 'Aluno',
        align: 'center',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Total checkins realizados',
        align: 'center',
        sortable: true,
        value: 'count'
      }
    ],
    pagination: {
      rowsPerPage: 10
    },
    paginationGroup: {
      rowsPerPage: 10,
      sortBy: 'count',
      descending: true
    }
  }),
  treinosAgrupados: [],
  treinosIndividualizados: [],
  computed: {
    computedDateStart () {
      return this.inputStartDate ? moment(this.inputStartDate).format('DD/MM/YYYY') : '';
    },
    computedDateEnd () {
      return this.inputEndDate ? moment(this.inputEndDate).format('DD/MM/YYYY') : '';
    }
  },
  created() {
    this.$root.exibirBarraTopo = true;
  },
  mounted: function() {
    let self = this;
    UserService.index().then(function(data) {
      self.users = data.data;
    });
  },
  methods: {
    formatarData: function(dateStr) {
      return moment(dateStr).format('DD/MM/YY HH:mm');
    },
    inputDate(isStartDate) {
      if (!this.dataPicker) {
        this.date = this.inputEndDate ? moment(this.inputEndDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
        if (isStartDate) {
          this.date = this.inputStartDate ? moment(this.inputStartDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
          this.startDate = this.date;
        } else {
          this.endDate = this.date;
        }
      }
      this.dataPicker = false;
    },
    getDate: function(isStartDate) {
      this.editingStartDate = false;
      this.date = this.endDate || new Date().toISOString().substr(0, 10);
      if (isStartDate) {
        this.editingStartDate = true;
        this.date = this.startDate || new Date().toISOString().substr(0, 10);
      }
      this.modal = true;
    },
    setDate: function() {
      this.dataPicker = true;
      if (this.editingStartDate) {
        this.startDate = this.date;
        this.inputStartDate = moment(this.startDate).format('DD/MM/YYYY');
      } else {
        this.endDate = this.date;
        this.inputEndDate = moment(this.endDate).format('DD/MM/YYYY');
      }
      this.modal = false;
      this.dataPicker = false;
    },
    generate: async function() {
      this.countCheckins = 0;
      let users = '';
      if (this.selectedUsers && this.selectedUsers.length) {
        for (let u of this.selectedUsers) {
          if (u._id) {
            users += ',' + u._id;
          }
        }
      }
      if (this.startDate === undefined || this.endDate === undefined) {
        this.snackbar = true;
        this.snackbarMessage = 'Informe uma data inicial e final para realizar a busca.';
        this.snackbarType = 'warning';
        this.snackbarTime = 5000;
        return;
      }
      let data = await WorkoutService.getExecutionsByDateAndUser(this.startDate, this.endDate, users.substr(1));
      if (data.data.length === 0) {
        this.snackbar = true;
        this.snackbarMessage = 'Sem dados para exibir.';
        this.snackbarType = 'info';
      }
      this.treinosIndividualizados = data.data;
      this.groupWorkoutsByUser();
    },

    totalCheckins() {
      let treinosClientes = [];
      this.countCheckins = 0;
      treinosClientes = this.groupBy(this.treinosIndividualizados, 'userID');
      this.treinosAgrupados = Object.values(treinosClientes);
      this.treinosAgrupados.forEach(treinos => {
        this.countCheckins += treinos.count;
      });
    },
    groupWorkoutsByUser() {
      this.totalCheckins();
      if (this.isGroup) {
        this.searchResults = this.treinosAgrupados;
      } else {
        this.searchResults = this.treinosIndividualizados;
        this.countCheckins = this.treinosIndividualizados.length;
      }
    },
    searchByUser: async function(userId) {
      let data = await WorkoutService.getExecutionsByDateAndUser(this.startDate, this.endDate, userId);
      if (data.data.length === 0) {
        this.snackbar = true;
        this.snackbarMessage = 'Sem dados para exibir.';
        this.snackbarType = 'info';
      }
      this.searchResults = data.data;
    },
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = {
            name: obj.userName[0],
            count: 0
          };
        }
        acc[key].count++;
        return acc;
      },
      {});
    }
    // checkIn: async function() {
    //   let exercisesReturn = await ExerciseService.index();
    //   let exercises = exercisesReturn.data;
    //   for (let ex of exercises) {
    //     let exercise = {
    //       text: ex.name,
    //       value: ex.id
    //     };
    //     this.exercises.push(exercise);
    //   }
    //   let workout = await WorkoutService.nextWorkoutForUser(this.code);
    //   this.workout = workout.data[0];
    //   this.dialog = true;
    // },
    // startWorkout: async function() {
    //   await WorkoutService.startWorkout(this.workout._id);
    //   this.snackbar = true;
    //   this.dialog = false;
    // },
    // skipWorkout: async function(confirmed) {
    //   if (!confirmed) {
    //     this.dialog = false;
    //     this.skipDialog = true;
    //   } else {
    //     await WorkoutService.skipWorkout(this.workout._id);
    //     this.checkIn();
    //     this.skipDialog = false;
    //   }
    // },
    // recuperarNomeExercicio: function(exerciseId) {
    //   if (exerciseId > -1) {
    //     let exercicio = this.exercises.filter(ex => ex.value === exerciseId)[0];
    //     return exercicio.text;
    //   } else {
    //     return undefined;
    //   }
    // },
    // calculateRowSpan(index, workoutExercises) {
    //   let wExercise = workoutExercises[index];
    //   let rowSpan = 0;
    //   for (let idx in workoutExercises) {
    //     if (idx !== index) {
    //       let exerciseLoop = workoutExercises[idx];
    //       if (
    //         exerciseLoop.order &&
    //         wExercise.order &&
    //         exerciseLoop.order === wExercise.order
    //       ) {
    //         rowSpan++;
    //       }
    //     }
    //   }
    //   return rowSpan;
    // }
  }
};
</script>

<style>
  @media(min-width: 1038px) {
    .classtextfield {
      margin-right: 30px;
    }
  }
  @media(max-width: 1038px) {
    .classtextfield {
      margin-right: 5px;
    }
  }

</style>
