import Api from '@/services/Api';

export default {
  async index (search) {
    return Api().get('sport', {
      params: {
        search: search
      }
    });
  },
  show (sportId) {
    return Api().get(`sport/${sportId}`);
  },
  post (sports) {
    return Api().post('sport', sports);
  },
  put (sports) {
    return Api().put(`sport/${sports._id}`, sports);
  },
  delete (sports) {
    return Api().delete(`class/${sports._id}`);
  }
};
