<template>
  <v-card
  class="mx-auto"
  width="256"
  tile
  >
    <v-navigation-drawer
    app
    v-model="$root.drawer"
    fixed
    temporary
    >
      <v-system-bar></v-system-bar>
      <v-list style="text-align: start" >
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="'https://ui-avatars.com/api/?name=' + $store.state.user.name"></v-img>
          </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ $store.state.user.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click.stop="$root.drawer = false">fa-chevron-left</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list
      nav
      dense
      data-cy="itens-menu"
      >
        <v-list-item-group color="primary">
          <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="alterarMenu(item), accessPermission()"
          v-show="accessPermission(item.permission)"
          >
            <v-list-item-icon>
              <v-icon style="font-size: 20px" v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content style="text-align: start">
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      appMounted: false,
      primaryColor: 'blue',
      items: [
        { title: 'Início', icon: 'fa-home', route: 'Início', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Home', icon: 'fa-home', route: 'Home do Usuário', exibirBarraTopo: true, permission: 'USER' },
        { title: 'Clientes', icon: 'fa-user', route: 'Clientes', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Exercícios', icon: 'fa-dumbbell', route: 'Exercícios', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Modalidades', icon: 'fa-tag', route: 'Modalidades', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Treinos', icon: 'fa-clipboard-list', route: 'Treinos', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Aulas', icon: 'fa-calendar-alt', route: 'Aulas', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Planos', icon: 'fa-file-signature', route: 'Planos', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Meus Treinos', icon: 'fa-dumbbell', route: 'Meus Treinos', exibirBarraTopo: true, permission: 'USER' },
        // { title: 'Checkin Meus Treinos', icon: 'fa-check', route: 'Checkin Meus Treinos', exibirBarraTopo: true, permission: 'USER' },
        { title: 'Checkin', icon: 'fa-play-circle', route: 'Checkins', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Checkin ', icon: 'fa-play-circle', route: 'Checkin do Usuário', exibirBarraTopo: true, permission: 'USER' },
        { title: 'Treinos em progresso', icon: 'fa-stopwatch', route: 'Treinos em Andamento', exibirBarraTopo: false, permission: 'ADMIN' },
        { title: 'Relatórios', icon: 'fa-table', route: 'Relatórios', exibirBarraTopo: true, permission: 'ADMIN' },
        { title: 'Alterar Senha', icon: 'fa-key', route: 'Alterar Senha', exibirBarraTopo: true, permission: 'all' },
        { title: 'Sair', icon: 'fa-power-off', route: 'Login', exibirBarraTopo: true, permission: 'all' }
      ],
      right: null
    };
  },
  mounted: function() {
    this.appMounted = true;
  },
  methods: {
    alterarMenu: function(menu) {
      if (menu.exibirBarraTopo === false) {
        this.$root.exibirBarraTopo = false;
      } else {
        this.$root.exibirBarraTopo = true;
      }
      if (menu.route !== this.$router.currentRoute.name) {
        this.$router.push({name: menu.route});
      } else {
        this.$router.push({name: menu.route});
        this.$router.go();
      }
      if (menu.route === 'Login') {
        this.$store.dispatch('setToken', null);
        this.$store.dispatch('setUser', null);
        this.$root.userLogado = false;
      }
    },
    accessPermission(permissionMenu) {
      let profile = this.$store.state.user.profile;
      if (permissionMenu === profile || permissionMenu === 'all') {
        return true;
      }
      return false;
    }
  }
};
</script>
