<template>
   <div>
    <v-layout row wrap justify-center :style="{'background-color': 'white', 'border-bottom': '2px solid rgba(0,0,0,.12)', 'margin-left':'0px', 'margin-right':'0px'}" >
      <div style="font-size:20px; align-self:center; margin-left:10px">Cadastro de Aulas</div>
      <v-divider
        class="mx-2"
        inset
        vertical
        ></v-divider>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
       <v-text-field
          :class="classTextField"
          v-model="search"
          append-icon="fa-search"
          label="Localizar"
          single-line
          hide-details
          data-cy="localizar-exercicios"
        ></v-text-field>
      <v-dialog v-model='dialog' max-width="1000px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn data-cy="novo-exercicio" raised class="mb-2" color="primary" v-bind="attrs" v-on="on" style="margin-top: 10px">Novo Horário</v-btn>
        </template>
        <v-card v-if="dialog">
          <v-card-title>
            <span :style="{'font-size':'24px', 'font-weight':'500'}">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs10 sm10 md10>
                  <v-select
                    label="Modalidade"
                    :items="sports"
                    v-model="editedItem.sport"
                  ></v-select>
                </v-flex>
                <v-flex xs2 sm2 md2>
                  <v-checkbox
                    v-model="editedItem.inactive"
                    label="Inativo"
                    color="primary"
                    hide-details
                    @change="(val) => editedItem.inactive === val"
                    :style="{'margin-left': '30px', 'max-inline-size': 'fit-content', 'margin-bottom': '20px'}"
                    data-cy="inativar"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs6 sm3 md3>
                  <v-text-field data-cy="nome" v-model="editedItem.description" label="Descrição"></v-text-field>
                </v-flex>
                <v-flex xs6 sm3 md3>
                  <v-text-field data-cy="instrutor" v-model="editedItem.coach" label="Instrutor"></v-text-field>
                </v-flex>
                <v-flex xs6 sm3 md3>
                  <v-text-field
                    v-mask="'##/##/####'"
                    ref="inputStartDate"
                    v-model="inputStartDate"
                    :value="computedDateStart"
                    label="Vigência Inicial"
                    append-icon="fa-calendar"
                    @click:append="getDate(true)"
                    @blur="inputDate(true)"
                    data-cy="data-inicial"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm3 md3>
                  <v-text-field
                    v-mask="'##/##/####'"
                    v-model="inputEndDate"
                    ref="inputEndDate"
                    :value="computedDateEnd"
                    label="Vigência Final"
                    append-icon="fa-calendar"
                    @click:append="getDate(false)"
                    @blur="inputDate(false)"
                    data-cy="data-final"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    label="Hora Inicial"
                    v-mask="'##:##'"
                    :class="classTextField"
                    v-model="editedItem.startTime"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    label="Hora Final"
                    v-mask="'##:##'"
                    v-model="editedItem.endTime"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field v-model='editedItem.vacancies' label="Total de Vagas"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 wrap>
                  <v-card outlined elevation="0">
                    <v-card-title style="color:cadetblue">
                      Dias da Semana
                    </v-card-title>
                    <v-layout wrap row >
                      <v-flex xs3 sm3 md3 v-for="(day, index) in weekDays" :key="index">
                        <v-checkbox
                          v-model="day.selected"
                          :label="day.day"
                          color="primary"
                          hide-details
                          @change="setWeekDays(day)"
                          :style="{'margin' : '20px 0 20px 20px', 'max-inline-size': 'fit-content'}"
                          data-cy="days"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="copyWorkout">Copiar</v-btn>
            <v-btn color="green darken-1" text @click.native="saveAndStay">Salvar</v-btn>
            <v-btn color="green darken-1" text @click.native="save">Salvar e Sair</v-btn>
            <v-btn color="red darken-1" text @click.native="dialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="date"
      width="290px"
    >
      <v-date-picker v-model="date" scrollable locale="pt-br" no-title @input="setDate">
      </v-date-picker>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTime"
      :color="snackbarType"
      :top="true"
    >
      {{snackbarMessage}}
    </v-snackbar>
    <v-data-table
      :headers='$vuetify.breakpoint.smAndDown ? headers : headers'
      :items='classes'
      class="elevation-1"
      :search='search'
      :options.sync="pagination"
      :footer-props="{
        'items-per-page-text': 'Registros por página'
      }"
      data-cy="lista-exercicios"
    >
    <template v-slot:[`item.weekDays`]="{ item }">
        {{formatDayOfTheWeekToShow(item.weekDays)}}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <td class="justify-center layout px-0">
          <v-icon
            small
            class="mr-2"
            title="Editar"
            @click="editItem(item)"
            data-cy="editar-exerc"
          >
            fa-edit
          </v-icon>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ClassService from '@/services/ClassScheduleService';
import SportService from '@/services/SportService';
const moment = require('moment');

export default {
  data: () => ({
    classes: [],
    search: '',
    classTextField: 'classtextfield',
    dialog: false,
    editedItem: {inactive: false, weekDays: [], startTime: '', endTime: ''},
    defaultItem: {inactive: false, weekDays: [], startTime: '', endTime: ''},
    editedIndex: -1,
    actualRegister: {},
    copied: false,
    inputStartDate: undefined,
    inputEndDate: undefined,
    date: new Date().toISOString().substr(0, 10),
    sports: [],
    modal: false,
    weekDays: [
      {selected: false, day: 'Todos', isDayOfWeek: false},
      {selected: false, day: 'Dias da Semana', isDayOfWeek: false},
      {selected: false, day: 'Segunda-Feira', isDayOfWeek: true},
      {selected: false, day: 'Terça-Feira', isDayOfWeek: true},
      {selected: false, day: 'Quarta-Feira', isDayOfWeek: true},
      {selected: false, day: 'Quinta-Feira', isDayOfWeek: true},
      {selected: false, day: 'Sexta-Feira', isDayOfWeek: true},
      {selected: false, day: 'Sábado', isDayOfWeek: true},
      {selected: false, day: 'Domingo', isDayOfWeek: true}
    ],
    snackbarTime: 3000,
    snackbar: false,
    snackbarMessage: '',
    snackbarType: '',
    pagination: {
      rowsPerPage: 10
    },
    headers: [
      {
        text: 'Modalidade',
        align: 'center',
        value: 'modalidade'
      },
      {
        text: 'Descrição',
        align: 'center',
        value: 'description'
      },
      {
        text: 'Instrutor',
        align: 'center',
        value: 'coach'
      },
      {
        text: 'Hora Inicial',
        align: 'center',
        value: 'startTime'
      },
      {
        text: 'Hora Final',
        align: 'center',
        value: 'endTime'
      },
      {
        text: 'Vigência Incial',
        align: 'center',
        value: 'startDateFormated'
      },
      {
        text: 'Vigência Final',
        align: 'center',
        value: 'endDateFormated'
      },
      {
        text: 'Dias da Semana',
        align: 'center',
        value: 'weekDays'
      },
      { text: 'Ações',
        value: 'action',
        sortable: false,
        align: 'center'
      }
    ]
  }),
  async created() {
    this.initialize();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Horário' : 'Editar Horário';
    },
    computedDateStart () {
      return this.inputStartDate ? moment(this.inputStartDate).format('DD/MM/YYYY') : '';
    },
    computedDateEnd () {
      return this.inputEndDate ? moment(this.inputEndDate).format('DD/MM/YYYY') : '';
    }
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.initialize();
      }
    }
  },
  methods: {
    async close() {
      this.dialog = false;
      await this.initialize();
    },
    async initialize() {
      this.editedIndex = -1;
      this.copied = false;
      let self = this;
      this.inputStartDate = undefined;
      this.inputEndDate = undefined;
      await SportService.index().then(function(data) {
        let sports = data.data;
        for (let s of sports) {
          let itemComboBox = {
            text: s.name,
            value: s._id
          };
          self.sports.push(itemComboBox);
        }
      });
      await ClassService.index().then(function(data) {
        self.classes = data.data;
        self.classes.forEach(classe => {
          let startDateFormated = new Date();
          let endDateFormated = new Date();
          startDateFormated = classe.startDate ? self.formatToShow(classe.startDate) : '';
          endDateFormated = classe.endDate ? self.formatToShow(classe.endDate) : '';
          Object.assign(classe, {startDateFormated: startDateFormated});
          Object.assign(classe, {endDateFormated: endDateFormated});
          self.sports.forEach(sport => {
            if (sport.value === classe.sport) {
              Object.assign(classe, {modalidade: sport.text});
            }
          });
        });
      });
      self.weekDays = [
        {selected: false, day: 'Todos', isDayOfWeek: false},
        {selected: false, day: 'Dias da Semana', isDayOfWeek: false},
        {selected: false, day: 'Segunda-Feira', isDayOfWeek: true},
        {selected: false, day: 'Terça-Feira', isDayOfWeek: true},
        {selected: false, day: 'Quarta-Feira', isDayOfWeek: true},
        {selected: false, day: 'Quinta-Feira', isDayOfWeek: true},
        {selected: false, day: 'Sexta-Feira', isDayOfWeek: true},
        {selected: false, day: 'Sábado', isDayOfWeek: false},
        {selected: false, day: 'Domingo', isDayOfWeek: false}
      ];
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    editItem(item) {
      this.editedIndex = this.classes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.checkWeekDays(item);
      this.inputStartDate = this.editedItem.startDate ? moment(this.editedItem.startDate).format('DD/MM/YYYY') : '';
      this.inputEndDate = this.editedItem.endDate ? moment(this.editedItem.endDate).format('DD/MM/YYYY') : '';
    },

    checkWeekDays(item) {
      if (item.weekDays.length > 0) {
        item.weekDays.forEach(day => {
          this.weekDays.forEach(weekDay => {
            if (day === this.formatDayOfTheWeekToSave(weekDay.day)) {
              weekDay.selected = true;
            }
          });
        });
      }
    },
    inputDate(isStartDate) {
      if (!this.dataPicker) {
        this.date = this.inputEndDate ? moment(this.inputEndDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
        if (isStartDate) {
          this.date = this.inputStartDate ? moment(this.inputStartDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
          this.editedItem.startDate = this.date;
        } else {
          this.editedItem.endDate = this.date;
        }
      }
      this.dataPicker = false;
    },
    getDate: function(isStartDate) {
      this.editingStartDate = false;
      this.date = this.editedItem.endDate || new Date().toISOString().substr(0, 10);
      if (isStartDate) {
        this.editingStartDate = true;
        this.date = this.editedItem.startDate || new Date().toISOString().substr(0, 10);
      }
      this.modal = true;
    },
    setDate: function() {
      this.dataPicker = true;
      if (this.editingStartDate) {
        this.editedItem.startDate = this.date;
        this.inputStartDate = moment(this.editedItem.startDate).format('DD/MM/YYYY');
      } else {
        this.editedItem.endDate = this.date;
        this.inputEndDate = moment(this.editedItem.endDate).format('DD/MM/YYYY');
      }
      this.modal = false;
      this.dataPicker = false;
    },
    formatToShow: function(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    copyWorkout() {
      if (this.copied) {
        this.snackbar = true;
        this.snackbarMessage = `Já existe um treino copiado pendente para ser salvo. Finalize a operação para realizar uma nova cópia.`;
        this.snackbarType = 'warning';
        this.snackbarTime = '8000';
        return;
      }
      let horaInicio = '';
      let minInicio = '';
      let horaFim = '';
      let minFim = '';
      horaInicio = this.editedItem.startTime.substr(0, 2);
      minInicio = this.editedItem.startTime.substr(3, 2);
      horaFim = this.editedItem.endTime.substr(0, 2);
      minFim = this.editedItem.endTime.substr(3, 2);
      horaInicio = +horaInicio + 1;
      horaFim = +horaFim + 1;
      horaInicio = horaInicio < 10 ? `0${horaInicio}` : horaInicio;
      horaFim = horaFim < 10 ? `0${horaFim}` : horaFim;
      this.$set(this.editedItem, 'startTime', `${horaInicio}:${minInicio}`);
      this.$set(this.editedItem, 'endTime', `${horaFim}:${minFim}`);
      this.editedItem.description = 'alterouuu';
      delete this.editedItem._id;
      this.snackbar = true;
      this.snackbarMessage = `Treino copiado, salve para finalizar a operação! Atenção: O horário do treino foi acrescido em 1(uma) hora em relação ao qual foi copiado.`;
      this.snackbarType = 'blue darken-2';
      this.snackbarTime = '8000';
      this.editedIndex = -1;
      this.copied = true;
      this.$forceUpdate();
    },
    async saveAndStay() {
      let self = this;
      if (this.editedIndex > -1) {
        await ClassService.put(this.editedItem).then(function() {
          self.snackbar = true;
          self.snackbarMessage = `Treino alterado com sucesso!`;
          self.snackbarType = 'success';
          self.snackbarTime = '5000';
          self.copied = false;
        });
      } else {
        await ClassService.post(this.editedItem).then(function() {
          self.snackbar = true;
          self.snackbarMessage = `Horário de treinos criado com sucesso!`;
          self.snackbarType = 'success';
          self.snackbarTime = '10000';
          self.copied = false;
        });
      }
    },
    save() {
      let self = this;
      if (this.editedIndex > -1) {
        ClassService.put(this.editedItem).then(function() {
          self.initialize();
          self.snackbar = true;
          self.snackbarMessage = `Horário de treinos alterado com sucesso!`;
          self.snackbarType = 'success';
        });
      } else {
        ClassService.post(this.editedItem).then(function() {
          self.initialize();
          self.snackbar = true;
          self.snackbarMessage = `Horário de treinos criado com sucesso!`;
          self.snackbarType = 'success';
        });
      }
      this.close();
    },

    formatDayOfTheWeekToSave(day) {
      let dayFormat = day === 'Segunda-Feira' ? 'Mon' : day === 'Terça-Feira' ? 'Tue' : day === 'Quarta-Feira' ? 'Wed' : day === 'Quinta-Feira' ? 'Thu' : day === 'Sexta-Feira' ? 'Fri' : day === 'Sábado' ? 'Sat' : day === 'Domingo' ? 'Sun' : null;
      return dayFormat;
    },
    formatDayOfTheWeekToShow(days) {
      let dayFormat = '';
      for(let day of days){
        dayFormat += day === 'Mon' ? 'Segunda-Feira' : day === 'Tue' ? 'Terça-Feira' : day === 'Wed' ? 'Quarta-Feira' : day === 'Thu' ? 'Quinta-Feira' : day === 'Fri' ? 'Sexta-Feira' : day === 'Sat' ? 'Sábado' : day === 'Sun' ? 'Domingo' : null;
        dayFormat += ', ';
      }
      return dayFormat.substring(0, dayFormat.length - 2);
    },
    setWeekDays(day) {
      if (day.day === 'Todos') {
        this.editedItem.weekDays = [];
        this.weekDays.forEach(weekDay => {
          if (!day.selected) {
            weekDay.selected = false;
            const index = this.editedItem.weekDays.indexOf(this.formatDayOfTheWeekToSave(weekDay.day));
            this.editedItem.weekDays.splice(index, 1);
          } else {
            weekDay.selected = true;
            if (weekDay.day !== 'Todos' && weekDay.day !== 'Dias da Semana') {
              this.editedItem.weekDays.push(this.formatDayOfTheWeekToSave(weekDay.day));
            }
          }
        });
      } else if (day.day === 'Dias da Semana') {
        this.editedItem.weekDays = [];
        this.weekDays.forEach(weekDay => {
          if (!day.selected) {
            weekDay.selected = false;
            const index = this.editedItem.weekDays.indexOf(weekDay.day);
            this.editedItem.weekDays.splice(index, 1);
          } else if (weekDay.isDayOfWeek) {
            weekDay.selected = true;
            this.editedItem.weekDays.push(this.formatDayOfTheWeekToSave(weekDay.day));
          }
        });
      } else if (day.selected && day.day !== 'Todos' && day.day !== 'Dias da Semana') {
        this.editedItem.weekDays.push(this.formatDayOfTheWeekToSave(day.day));
      } else if (!day.selected && day.day !== 'Todos' && day.day !== 'Dias da Semana') {
        const index = this.editedItem.weekDays.indexOf(this.formatDayOfTheWeekToSave(day.day));
        this.editedItem.weekDays.splice(index, 1);
      }
    }
  }
};
</script>

<style>
 @media(min-width: 1038px) {
    .classtextfield {
      margin-right: 30px;
    }
  }
  @media(max-width: 1038px) {
    .classtextfield {
      margin-right: 5px;
    }
  }
</style>
