import Vue from "vue";
import App from "./App.vue";
import '@fortawesome/fontawesome-free/css/all.css';
import "./registerServiceWorker";
import router from "./router";
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import store from '@/store/store';
import Panel from '@/components/globals/Panel';
import { VueMaskDirective } from 'v-mask';
import './plugins/vuetify-mask.js';
import './registerServiceWorker';
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask);

Vue.config.productionTip = false;

const opts = { icons: { iconfont: 'fa' } };
Vue.use(Vuetify);

Vue.component('panel', Panel);

Vue.directive('mask', VueMaskDirective);

new Vue({
  router,
  store,
  vuetify: new Vuetify(opts),
  render: h => h(App),
  data() {
    return {
      drawer: false,
      exibirBarraTopo: true,
      exibirNavBar: true,
      userLogado: false,
      exibirFooter: false
    };
  }
}).$mount("#app");
