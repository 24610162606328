import Api from '@/services/Api';

export default {
  async index (search) {
    return Api().get('class', {
      params: {
        search: search
      }
    });
  },
  show (classId) {
    return Api().get(`class/${classId}`);
  },
  post (classes) {
    return Api().post('class', classes);
  },
  put (classes) {
    return Api().put(`class/${classes._id}`, classes);
  },
  delete (classes) {
    return Api().delete(`class/${classes._id}`);
  }
};
