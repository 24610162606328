<template>
  <v-container
    fluid
    >
    <v-layout wrap justify="space-around" >
      <v-flex xs12 sm12 md12>
        <v-card style="margin-bottom: 80px">
          <v-card-title primary-title style="justify-content:center">
            <div>
              <h3 :style="{'font-size':'20px', 'font-weight':'500'}" class="mb-0">Bem-vindo(a) {{$store.state.user.name}}! <br> O que você deseja fazer?</h3>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md12>
        <v-btn
          elevation="2"
          color="primary"
          class="buttons"
          @click="goMyWorkouts()"
        >
          <v-icon left
            style="padding-right:10px"
          >
            fa-dumbbell
          </v-icon>
        Acessar Meus Treinos
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 md12>
        <v-btn
          elevation="2"
          color="primary"
          class="buttons"
          @click="goCheckin()"
        >
          <v-icon left
            style="padding-right:10px"
          >
            fa-check
          </v-icon>
          Realizar Checkin
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 md12>
        <v-btn
          elevation="2"
          color="primary"
          class="buttons"
          @click="goChangePassword()"
        >
          <v-icon left
            style="padding-right:10px"
          >
            fa-key
          </v-icon>
          Alterar Senha
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  methods: {
    goMyWorkouts() {
      this.$router.push({ path: '/meustreinos' });
    },
    goCheckin() {
      this.$router.push({ path: '/usercheckin' });
    },
    goChangePassword() {
      this.$router.push({ path: '/changepassword' });
    }
  }

};
</script>

<style>
@media (min-width: 600px) {
  .buttons {
    width: 400px;
    margin-bottom: 100px;
    margin-left: 20px;
  }
}
@media (max-width: 599px) {
  .buttons {
    width: 270px;
    margin-bottom: 30px;
  }
}

</style>
