import Api from '@/services/Api';

export default {
  async index (search) {
    return Api().get('exercise', {
      params: {
        search: search
      }
    });
  },
  show (exerciseId) {
    return Api().get(`exercise/${exerciseId}`);
  },
  post (exercise) {
    return Api().post('exercise', exercise);
  },
  put (exercise) {
    return Api().put(`exercise/${exercise._id}`, exercise);
  },
  delete (exercise) {
    return Api().delete(`exercise/${exercise._id}`);
  }
};
