<template>
  <div>
    <v-layout row wrap justify-center :style="{'background-color': 'white', 'border-bottom': '2px solid rgba(0,0,0,.12)', 'margin-left':'0px', 'margin-right':'0px'}" >
      <div style="font-size:20px; align-self:center; margin-left:10px">Modalidades</div>
      <v-divider
        class="mx-2"
        inset
        vertical
        ></v-divider>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
       <v-text-field
          :class="classTextField"
          v-model="search"
          append-icon="fa-search"
          label="Localizar"
          single-line
          hide-details
          data-cy="localizar-exercicios"
        ></v-text-field>
      <v-dialog v-model='dialog' max-width="1000px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn data-cy="novo-exercicio" raised class="mb-2" color="primary" v-bind="attrs" v-on="on" style="margin-top: 10px">Nova Modalidade</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span :style="{'font-size':'24px', 'font-weight':'500'}">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm9 md9>
                  <v-text-field data-cy="nome" v-model="editedItem.name" label="Nome"></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md2>
                  <v-btn-toggle
                    v-model="editedItem.typeWorkout"
                    color="primary"
                    shaped
                    group
                  >
                    <v-btn value="personal">
                      Individual
                    </v-btn>

                    <v-btn value="coletivo">
                      Coletivo
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    filled
                    label="Descrição"
                    auto-grow
                    v-model="editedItem.description"
                    data-cy="descricao"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click.native="close">Cancelar</v-btn>
            <v-btn color="green darken-1" text @click.native="save">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTime"
        :color="snackbarType"
        :top="true"
      >
        {{snackbarMessage}}
      </v-snackbar>
    <v-data-table
      :headers='$vuetify.breakpoint.smAndDown ? headersMobile : headers'
      :items='sports'
      class="elevation-1"
      :search='search'
      :options.sync="pagination"
      :footer-props="{
        'items-per-page-text': 'Registros por página'
      }"
      data-cy="lista-exercicios"
    >
      <template v-slot:[`item.action`]="{ item }">
        <td class="justify-center layout px-0">
          <v-icon
            small
            class="mr-2"
            title="Editar"
            @click="editItem(item)"
            data-cy="editar-exerc"
          >
            fa-edit
          </v-icon>
          <v-icon
            small
            title="Excluir"
            @click="validateItem(item)"
            data-cy="excluir-exerc"
          >
            fa-trash
          </v-icon>
        </td>
      </template>
      <!-- <template slot="items" slot-scope="props">
          <td>{{ props.item._id }}</td>
          <td class="text-xs-center">{{ props.item.name }}</td>
          <td class="text-xs-center" v-if="$vuetify.breakpoint.smAndUp">{{ props.item.description }}</td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              title="Editar"
              @click="editItem(props.item)"
            >
              fa-edit
            </v-icon>
            <v-icon
              small
              title="Excluir"
              @click="validateItem(props.item)"
            >
              fa-trash
            </v-icon>
          </td>
        </template> -->
        <template slot="no-data">
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
    <v-dialog
      v-model="dialogConfirmacao"
      max-width="290"
    >
      <v-card>
        <v-card-title :style="{'font-size':'24px', 'font-weight':'500'}">Atenção</v-card-title>

        <v-card-text>
          Confirma exclusão da modalidade?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="dialogConfirmacao = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="deleteItem(itemExcluir)"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SportService from '@/services/SportService';
import ClassService from '@/services/ClassScheduleService';

export default {
  data: () => ({
    classTextField: 'textfield',
    search: '',
    dialog: false,
    dialogConfirmacao: false,
    snackbar: false,
    snackbarMessage: '',
    snackbarType: '',
    snackbarTime: 3000,
    itemExcluir: [],
    headers: [
      {
        text: 'Nome',
        align: 'center',
        value: 'name'
      },
      {
        text: 'Descrição',
        align: 'center',
        value: 'description'
      },
      { text: 'Ações', value: 'action', sortable: false, align: 'center' }
    ],
    headersMobile: [
      {
        text: 'Nome',
        align: 'center',
        value: 'name'
      },
      { text: 'Ações', value: 'action', sortable: false, align: 'center' }
    ],
    sports: [],
    editedIndex: -1,
    editedItem: {typeWorkout: 'coletivo'},
    defaultItem: {typeWorkout: 'coletivo'},
    pagination: {
      rowsPerPage: 10
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nova Modalidade' : 'Editar Modalidade';
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.$root.exibirBarraTopo = true;
    this.initialize();
  },

  methods: {
    initialize() {
      let self = this;
      SportService.index().then(function(data) {
        self.sports = data.data;
      });
    },

    editItem(item) {
      this.editedIndex = this.sports.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let self = this;
      SportService.delete(item).then(function() {
        self.snackbar = true;
        self.snackbarMessage = `Modalidade ${item.name} removida com sucesso!`;
        self.snackbarType = 'success';
        self.initialize();
      });
      this.snackbar = true;
      this.snackbarMessage = 'Operação não realizada!';
      this.snackbarType = 'warning';
      this.dialogConfirmacao = false;
    },

    validateItem(item) {
      this.itemExcluir = item;
      let self = this;
      ClassService.index(item._id).then(function(data) {
        let classes = data;
        if (classes.data === undefined || classes.data.length === 0) {
          self.dialogConfirmacao = true;
        } else {
          self.snackbar = true;
          self.snackbarMessage = 'A modalidade selecionada não pode ser excluída pois já está sendo utilizada em aulas cadastradas!';
          self.snackbarType = 'warning';
          self.snackbarTime = 5000;
          self.dialogConfirmacao = false;
        }
      });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      let self = this;
      if (this.editedIndex > -1) {
        SportService.put(self.editedItem).then(function() {
          self.initialize();
          self.snackbar = true;
          self.snackbarMessage = `Modalidade ${self.editedItem.name} alterada com sucesso!`;
          self.snackbarType = 'success';
        });
      } else {
        SportService.post(self.editedItem).then(function() {
          self.initialize();
          self.snackbar = true;
          self.snackbarMessage = `Modalidade ${self.editedItem.name} criada com sucesso!`;
          self.snackbarType = 'success';
        });
      }
      this.close();
    }
  }
};
</script>

<style>
  .textfield {
    padding-right: 30px;
    padding-bottom: 5px;
  }
  @media (max-width: 600px) {
    .textfield {
      padding-right: 0px;
      padding-bottom: 5px;
    }
  }

</style>
