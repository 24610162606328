<template>
  <v-container
    fluid
    >
    <v-row>
      <v-col
        cols="12"
        lg="6"
        xs="12"
        >
        <v-card>
          <h3 style="font-size:1.2rem">Checkins por Dia da Semana</h3>
          <div style="position: relative; height:400px">
            <canvas v-if="displayChart" id="chartCheckinsWeek"></canvas>
             <div v-else>
              <v-icon style="font-size: 90px; padding: 70px 0 30px 0"> fa-stopwatch </v-icon>
              <p>
                Não existem checkins realizados
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        xs="12"
        >
        <v-card style="background-color: #512121">
          <v-img class="margin-logo" :src="require('@/assets/background-image.png')" height="125px" />
        </v-card>
      <v-row>
        <v-col
        cols="12"
        lg="6"
        xs="12"
          >
          <v-card style="background-color: #3fa84d">
            <v-icon style="color: #ffffff; font-size: 123px; padding: 25px 25px 25px 25px"> fa-user-check </v-icon>
            <div style="color: #ffffff; font-size: 45px; font-weight: bold">
                {{countStatus[0]}}
            </div>
            <p style="color: #ffffff; padding-bottom: 24px; font-size: 20px">
              Alunos Ativos
            </p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          xs="12"
          >
          <v-card style="background-color: #ffa332; text-align: justify; margin-bottom: 17px">
            <div class="d-flex flex-wrap justify-space-between">
              <div>
                <v-card-title style="color: #ffffff; font-size: 45px; padding: 25px 35px 30px 35px; font-weight: bold">
                  {{calcMediaCheckinsSemanal}}
                </v-card-title>
                <v-card-subtitle style="color: #ffffff; font-size: 20px; padding: 0 0 15px 35px">
                  Média <br>
                  checkins<br>
                  semanal

                </v-card-subtitle>
              </div>
                <v-icon style="color: #ffffff; font-size: 75px; padding: 0 45px 15px 0"> fa-calendar-check </v-icon>
            </div>
          </v-card>
          <v-card style="background-color: #30d4f4; text-align: justify; margin-top: 17px">
            <div class="d-flex flex-wrap justify-space-between">
              <div>
                <v-card-title style="color: #ffffff; font-size: 45px; padding: 20px 35px 30px 35px; font-weight: bold">
                  {{workouts.length}}
                </v-card-title>
                <v-card-subtitle style="color: #ffffff; font-size: 20px; padding: 0 0 15px 35px">
                  Workouts<br>
                  cadastrados
                </v-card-subtitle>
              </div>
                <v-icon style="color: #ffffff; font-size: 70px; padding: 0 35px 0 0"> fa-dumbbell </v-icon>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
      <v-col
        cols="12"
        lg="2"
        xs="12"
        >
        <v-card>
          <v-card-title primary-title>
            <div>
              <h3 style="font-size:1.2rem"> Próximos Aniversariantes</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <table style="width: 100%; font-size: 1.2rem; text-align: left; height: 350px">
              <tr v-for="b in birthdays" :key="b.name">
                <td>{{ b.name }}</td>
                <td>{{ b.birthDate }}</td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
        <v-col
        cols="12"
        lg="12"
        xs="12"
      >
        <v-card>
          <h3 style="font-size:1.2rem">Checkins Realizados Últimos 30 dias</h3>
          <div style="position: relative; height:30vh">
            <canvas v-if="displayChart" id="chartCheckins"></canvas>
            <div v-else>
              <v-icon style="font-size: 90px; padding: 70px 0 30px 0"> fa-stopwatch </v-icon>
              <p>
                Não existem checkins realizados
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WorkoutService from '@/services/WorkoutService';
import UserService from '@/services/UserService';
const moment = require('moment');
var Chart = require('chart.js');

export default {
  data: () => ({
    displayChart: false,
    labels: [],
    values: [],
    birthdays: [],
    userStatus: [],
    countStatus: [0, 0],
    totalCheckins: 0,
    checkinsByDaysOfWeek: [0, 0, 0, 0, 0, 0, 0],
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    chartCheckinsOptions: {
      values: [],
      labels: [],
      labelHeader: '',
      type: 'line',
      legend: false,
      displayTick: true,
      borderColors: [],
      bkgColors: []
    },
    chartCheckinsByWeekOptions: {
      values: [0, 0, 0, 0, 0, 0, 0],
      labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      labelHeader: '',
      type: 'doughnut',
      legend: true,
      displayTick: false,
      borderColors: [],
      bkgColors: ['#f9e4d5', '#ff6384', '#ffcd56', '#36a2eb', '#81a367', '#90bcc6', '#e88945', '#9cef6b']
    },
    workouts: []
  }),

  created() {
    this.initialize();
  },
  computed: {
    calcMediaCheckinsSemanal() {
      return Math.round(this.totalCheckins / 4);
    }
  },

  methods: {
    async initialize() {
      let actualDay = this.getDayOfYear();
      let daysAgo = this.getDayOfYear() - 29;
      this.$root.exibirBarraTopo = true;
      this.$root.exibirNavBar = true;
      let self = this;
      self.labels = [];
      self.values = [];
      await WorkoutService.getCheckinsByDaysAgo(30).then(function(data) {
        let checkins = data.data;
        checkins.sort(function(a, b) {
          return a._id - b._id;
        });
        for (let index = daysAgo; index <= actualDay; index++) {
          for (let ck of checkins) {
            if (ck._id === index.toString() && ck.count > 0) {
              self.values.push(ck.count);
              self.labels.push(self.dayOfYearToDate(index - 1));
              self.totalCheckins += ck.count;
              self.chartCheckinsPorDiasSemana(index, ck.count);
              self.displayChart = true;
            }
          }
        }
      });
      await UserService.index().then(function(data) {
        self.userStatus = data.data;
        self.userStatus.forEach(user => {
          if (user.status === 'ACTIVE') {
            self.countStatus[0]++;
          } else {
            self.countStatus[1]++;
          }
        });
      });
      let birthdays = await UserService.getBirthdays('upcoming');
      if (birthdays && birthdays.data) {
        for (let b of birthdays.data) {
          let dateF = moment(b.birthDate).format('DD/MM');
          b.birthDate = dateF;
          this.birthdays.push(b);
        }
      }
      await WorkoutService.index(
      ).then(function(data) {
        self.workouts = data.data;
      });
      await this.dashBoardGenerate();
    },
    async dashBoardGenerate() {
      this.$nextTick(function () {
        this.chartGenerate('chartCheckins', this.chartCheckins());
      });
      this.$nextTick(function () {
        this.chartGenerate('chartCheckinsWeek', this.chartCheckinsByWeekOptions);
      });
    },
    chartGenerate(idElement, options) {
      if (!this.displayChart) {
        return;
      }
      var ctx = '';
      this.$nextTick(function () {
        ctx = document.getElementById(idElement).getContext('2d');
      });
      this.$nextTick(function () {
        var chart = new Chart(ctx, {
          type: options.type,
          data: {
            labels: options.labels,
            datasets: [{
              label: options.labelHeader,
              data: options.values,
              backgroundColor: options.bkgColors,
              borderColor: options.type === 'line' ? '#ff6384' : options.borderColors,
              borderWidth: 2,
              fill: false
            }]
          },
          options: {
            legend: {
              display: options.legend
            },
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                ticks: {
                  display: options.displayTick !== undefined ? options.displayTick : true,
                  beginAtZero: true,
                  stepSize: 1
                }
              }]
            }
          }
        });
        return chart;
      });
    },
    chartCheckins() {
      this.chartCheckinsOptions.labels = this.labels;
      let colorRandom = '';
      this.values.forEach(value => {
        colorRandom = this.getRandomColor();
        this.chartCheckinsOptions.borderColors.push(colorRandom);
        colorRandom += '70';
        this.chartCheckinsOptions.bkgColors.push(colorRandom);
        if (Number(value) > 0) {
          this.chartCheckinsOptions.values.push(value);
        }
      });
      return this.chartCheckinsOptions;
    },
    chartCheckinsPorDiasSemana(day, count) {
      var now = new Date();
      var days = this.chartCheckinsByWeekOptions.labels;
      let start = new Date(now.getFullYear(), 0, 0);
      start.setDate(start.getDate() + day);
      var dayWeek = days[start.getDay()];
      var index = days.indexOf(dayWeek);
      let valueArray = this.chartCheckinsByWeekOptions.values[index];
      this.chartCheckinsByWeekOptions.values[index] = valueArray + count;
    },
    dayOfYearToDate(day) {
      var oneDay = 1000 * 60 * 60 * 24;
      var dayInMilleseconds = new Date(oneDay * day);
      var dayInMillesecondsToDate = new Date(dayInMilleseconds);
      return moment(dayInMillesecondsToDate).format('DD/MM');
    },
    getDaysByData(totalDaysAgo) {
      let now = new Date();
      let allDates = [];
      for (let i = totalDaysAgo; i >= 0; i--) {
        allDates.push(new Date(now.setDate(now.getDate() - i)));
        now = new Date();
      }
      let allDatesFormated = [];
      allDates.forEach(data => {
        allDatesFormated.push(moment(data).format('DD/MM'));
      });
      return allDatesFormated;
    },
    getDayOfYear() {
      var now = new Date();
      var start = new Date(now.getFullYear(), 0, 0);
      var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
</script>
