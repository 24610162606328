import Api from '@/services/Api';

export default {
  async index (search) {
    return Api().get('plan', {
      params: {
        search: search
      }
    });
  },
  show (planId) {
    return Api().get(`plan/${planId}`);
  },
  post (plan) {
    return Api().post('plan', plan);
  },
  put (plan) {
    return Api().put(`plan/${plan._id}`, plan);
  },
  delete (plan) {
    return Api().delete(`plan/${plan._id}`);
  }
};
