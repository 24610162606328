<template>
  <div>
    <v-container grid-list-lg style="padding: 0; margin: 0; max-width: 100%">
      <div class="lighten-1 header-treinos">
        <h2 class="black--text" v-if="!userQuery">Treinos: Modelos de Treino</h2>
        <h2 class="black--text" v-if="userQuery">Treinos: {{ `${userQuery.name} (Código: ${userQuery.id})` }}</h2>
      </div>
      <v-layout v-if="workouts.length > 0" row wrap class="workouts-list">
        <v-flex xs12 sm6 md4 lg3 v-for="workout in workouts" :key="workout.id">
          <v-card data-cy="card-treino">
            <v-card-title >
              <h3 style="width: auto">{{ workout.name }}</h3>
              <v-spacer></v-spacer>
              <v-btn data-cy="btn-checkin" title="Fazer checkin" @click="dialogConfirmacao = true, workoutStarted = workout" color="green" outlined small dark class="mr-5">
                <v-icon size=1rem style="margin-right: 10px">fa-check</v-icon>
                Checkin
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div :style="{'margin-top':'10px', 'padding-bottom':'10px', 'font-size':'18px', 'font-weight':'300', 'white-space':'pre'}">
                {{workout.description}}
              </div>
              <table style="width: 100%">
                <tr>
                    <th align="left">Exercício</th>
                    <th width="40" style="min-width: 40px">Reps</th>
                    <th width="40" style="min-width: 40px">Séries</th>
                </tr>
                <tr data-cy="card-treinos" v-for="(exercise, index) in workout.exercises" :key="index" :class="Math.floor(exercise.order) % 2 == 0 ? 'dark' : 'light'">
                  <td data-cy="exerc-nome" class="exercise-cell" align="left">{{ exercise.exercise.name }}</td>
                  <td data-cy="exerc-reps">{{ exercise.reps }}</td>
                  <td data-cy="exerc-ordem" :rowspan="calculateRowSpan(index, workout.exercises)" v-if="!workout.exercises[index-1] || (workout.exercises[index-1].order !== exercise.order)">{{ exercise.series }}</td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center v-else style="padding-top: 100px">
        <v-flex xs12 sm12 md12 style="flex: none">
          <v-img
            style="padding-top: 100px"
            max-height="500"
            max-width="500"
            :src="require('@/assets/meditation.svg')"
          >
          </v-img>
        </v-flex >
        <v-flex xs12 sm12 md12 style="font-size: 30px; margin-top: 30px">
          Nenhum treino cadastrado para hoje!
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="dialogConfirmacao"
      max-width="290"
    >
      <v-card>
        <v-card-title :style="{'font-size':'20px', 'font-weight':'500'}">Atenção</v-card-title>
        <v-card-text>
          Realizar checkin para o treino: <br/> <b>{{workoutStarted ? workoutStarted.name : ''}}</b>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialogConfirmacao = false, workoutStarted = ''"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="userCheckin(workoutStarted)"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTime"
      :color="snackbarType"
      :top="true"
    >
      {{snackbarMessage}}
    </v-snackbar>
  </div>

</template>

<script>
import ExerciseService from '@/services/ExerciseService';
import WorkoutService from '@/services/WorkoutService';
import UserService from '@/services/UserService';

export default {
  data: () => ({
    userQuery: undefined,
    dialog: false,
    dialogConfirmacao: false,
    snackbar: false,
    snackbarMessage: '',
    snackbarType: '',
    snackbarTime: 3000,
    workouts: [],
    exercises: [],
    workoutStarted: undefined,
    users: []
  }),
  created() {
    this.$root.exibirBarraTopo = true;
    this.$router.push({ path: '/meustreinos', query: { user: this.$store.state.user._id } });
    let self = this;
    let userId = this.$route.query.user;
    UserService.index({_id: userId}).then(function(dataUser) {
      self.userQuery = dataUser.data[0];
      self.initialize();
    });
  },

  methods: {
    async initialize () {
      let self = this;
      ExerciseService.index().then(function(data) {
        let exercises = data.data;
        for (let ex of exercises) {
          let itemComboBox = {
            text: ex.name,
            value: ex._id
          };
          self.exercises.push(itemComboBox);
        }
      });
      WorkoutService.index(
        {
          status: 'active',
          user: self.userQuery ? self.userQuery._id : null
        }
      ).then(function(data) {
        self.workouts = [];
        for (let workout of data.data) {
          workout.exercises = self.orderedExercises(workout.exercises);
          self.workouts.push(workout);
        }
      });
    },
    orderedExercises: function(exercises) {
      exercises = exercises.sort(function(a, b) {
        return a.order < b.order ? -1 : 1;
      });
      return exercises;
    },
    calculateRowSpan(index, workoutExercises) {
      let wExercise = workoutExercises[index];
      let rowSpan = 0;
      for (let idx in workoutExercises) {
        if (idx !== index) {
          let exerciseLoop = workoutExercises[idx];
          if (exerciseLoop.order && wExercise.order && exerciseLoop.order === wExercise.order) {
            rowSpan++;
          }
        }
      }
      return rowSpan;
    },
    async userCheckin(workout) {
      await WorkoutService.startWorkout(workout._id);
      this.snackbar = true;
      this.snackbarMessage = 'Treino iniciado!';
      this.snackbarType = 'success';
      this.workoutStarted = '';
      this.dialogConfirmacao = false;
    }
  }
};
</script>

<style>
  .header-treinos {
     background: linear-gradient(to right, #7c7676, #ddd9d9);
  }
</style>
<style>
