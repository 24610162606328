import Api from '@/services/Api';

export default {
  async index (search) {
    return Api().get('workout', {
      params: {
        search: search
      }
    });
  },
  async show (workoutId) {
    return Api().get(`workout/${workoutId}`);
  },
  async post (workout) {
    return Api().post('workout', workout);
  },
  async put (workout) {
    return Api().put(`workout`, workout);
  },
  async delete (workout) {
    return Api().delete(`workout/${workout._id}`);
  },

  async listInProgress () {
    let query = await Api().get('workoutsInProgress');
    return query.data;
  },
  async nextWorkoutForUser (idUser) {
    return Api().get(`nextUserWorkout/${idUser}`);
  },
  async startWorkout (idWorkout, duration) {
    return Api().post(`startWorkout/${idWorkout}` + (duration !== undefined ? `?duration=${duration}` : ''));
  },
  async skipWorkout (idWorkout, duration) {
    return Api().post(`skipWorkout/${idWorkout}`);
  },
  async terminateWorkoutInProgress(idWorkout, idExecution) {
    return Api().post(`terminateWorkoutInProgress/${idWorkout}/${idExecution}`);
  },
  async getCheckinsByUserAndDaysAgo(userId, daysAgo) {
    return Api().get(`workoutsExecutionsByUser/${userId}/${daysAgo}`);
  },
  async getCheckinsByDaysAgo(daysAgo) {
    return Api().get(`workoutsExecutions/${daysAgo}`);
  },
  async getExecutionsByDateAndUser(startDate, endDate, user) {
    user = user || '';
    return Api().get(`getExecutionsByDateAndUser/${startDate}/${endDate}/${user}`);
  },
  async getWorkoutByExercise (exercise) {
    return Api().get(`workoutByExercise/${exercise}`);
  }
};
