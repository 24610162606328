<template>
  <div>
    <v-flex xs12 sm6 style="margin-bottom: 20px" >
      <v-card>
        <v-card-title primary-title>
          <div>
            <h4 style="font-size:1.5rem">Checkins nas últimas semanas</h4>
          </div>
        </v-card-title>
        <v-card-text>
          <v-sparkline v-if="showSparkline"
            :gradient="['#f72047', '#ffd200', '#1feaea']"
            :labels="labels"
            :value="values"
            :line-width="2"
            :label-size="5"
            stroke-linecap="round"
            auto-draw
            :padding="20"
          ></v-sparkline>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-container grid-list-lg style="padding: 0; margin: 0; max-width: 100%">

      <div class="lighten-1 header-treinos">
        <h2 class="white--text" v-if="!userQuery">Treinos: Modelos de Treino</h2>
        <h2 class="white--text" v-if="userQuery">Treinos: {{ `${userQuery.name} (Código cliente: ${userQuery.id})` }}</h2>
      </div>

      <draggable class="layout workouts-list row wrap" v-model="workouts" group="workouts" draggable=".workout-card" handle=".workout-drag" @end="updateOrder">
        <!-- <v-layout row wrap class="workouts-list"> -->
          <v-flex xs12 sm6 md4 lg3 v-for="workout in workouts" :key="workout.id" class="workout-card">
            <v-card data-cy="card-treino" class="handle">
              <v-card-title >
                <v-icon size=1.2rem class="workout-drag" style="margin-right: 5px; cursor: move">fa-grip-vertical</v-icon>
                <h3 style="width: auto">{{ getTypeWorkout(workout.sport) === 'personal' ? workout.name : workout.modalidade }}</h3>
                <v-spacer></v-spacer>
                     <v-btn
                  elevation="2"
                  icon
                  medium
                  tile
                  title="Editar Treino"
                  style="margin-right: 10px; min-width:0px; color:blue"
                  @click="editItem(workout)"
                >
                  <v-icon style="font-size: 18px">fas fa-edit</v-icon>
                </v-btn>
                <v-checkbox
                  data-cy="check-select"
                  @change="(val) => checkWorkout(val, workout)"
                ></v-checkbox>
              </v-card-title>
              <v-card-text v-if="workout.exercises.length > 0">
                <div :style="{'margin-top':'10px', 'padding-bottom':'10px', 'font-size':'18px', 'font-weight':'300', 'white-space':'pre'}">
                  {{workout.description}}
                </div>
                <table style="width: 100%">
                  <tr>
                      <th align="left">Exercício</th>
                      <th width="40" style="min-width: 40px">Reps</th>
                      <th width="40" style="min-width: 40px">Séries</th>
                  </tr>
                  <tr data-cy="card-treinos" v-for="(exercise, index) in workout.exercises" :key="index" :class="Math.floor(exercise.order) % 2 == 0 ? 'dark' : 'light'">
                    <td data-cy="exerc-nome" class="exercise-cell" align="left">{{ exercise.exercise.name }}</td>
                    <td data-cy="exerc-reps">{{ exercise.reps }}</td>
                    <td data-cy="exerc-ordem" :rowspan="calculateRowSpan(index, workout.exercises)" v-if="!workout.exercises[index-1] || (workout.exercises[index-1].order !== exercise.order)">{{ exercise.series }}</td>
                  </tr>
                </table>
              </v-card-text>
               <v-card-text v-else>
                <table style="width: 100%">
                  <tr>
                      <th width="40" align="left">Data</th>
                      <th width="40" align="left">Nome</th>
                      <th width="100" style="min-width: 40px">Descrição</th>
                  </tr>
                  <tr>
                    <td align="left">{{formatToShow(workout.workoutDate)}}</td>
                    <td align="left">{{workout.name}}</td>
                    <td>{{workout.description}}</td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-flex>
        <!-- </v-layout> -->
      </draggable>
    </v-container>

    <v-speed-dial v-model="fab" :direction="'top'" :absolute="false" :left="false" :top="false" :bottom="true" :right="true" :fixed="true" style="padding-bottom: 70px" transition="slide-y-reverse-transition">
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="blue darken-2"
          dark
          fab
          data-cy="expandir-botoes"
        >
          <v-icon v-if="!fab">fa-plus</v-icon>
          <v-icon v-else>fa-times</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="green"
        title="Adicionar treino"
        @click="addWorkout"
        data-cy="add-treino"
      >
        <v-icon>fa-plus</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="indigo"
        title="Copiar para outros usuários"
        @click="copyWorkoutsToUsers"
        data-cy="copiar-treinos"
      >
        <v-icon>fa-copy</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
        title="Excluir treinos"
        @click="validateSelectedWorkouts"
        data-cy="excluir-treinos"
      >
        <v-icon>fa-trash</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-dialog
      v-model="dialogConfirmacao"
      max-width="290"
      >
      <v-card>
        <v-card-title :style="{'font-size':'20px', 'font-weight':'500'}">Atenção</v-card-title>

        <v-card-text>
          Confirma exclusão do(s) treino(s) selecionados?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="dialogConfirmacao = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="deleteSelectedWorkouts"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='dialog' max-width="800px">
        <v-card>
          <v-card-title>
            <span :style="{'font-size':'24px', 'font-weight':'500'}">{{formTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field ref="nomeWorkout" data-cy="nome-exerc" v-model="editedItem.name" style="padding-right:10px" label="Nome"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md9>
                  <v-select
                    label="Modalidade"
                    :items="sports"
                    v-model="editedItem.sport"
                    :style="{'padding-right':'10px'}"
                    @change="showExercises"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    label="Data do Treino"
                    v-mask="'##/##/####'"
                    ref="inputStartDate"
                    v-model="inputStartDate"
                    :value="computedDateStart"
                    append-icon="fa-calendar"
                    @click:append="getDate(true)"
                    @blur="inputDate(true)"
                    data-cy="data-treino"
                    :style="{'padding-right':'10px'}"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-flex xs12 sm12 md12>
                <v-textarea
                  label="Descrição"
                  :rows="3"
                  v-model='editedItem.description'
                  data-cy="desc-exerc"
                ></v-textarea>
              </v-flex>
                <v-layout v-show="showExercise" style="padding-left:10px" row v-for="(exercise, index) in editedItem.exercises" :key="index">
                  <v-flex xs12 sm12 md6>
                    <v-combobox
                      :items="exercises"
                      item-text="text"
                      item-value="value"
                      @change="(value) => exercise.exercise._id = value ? value.value : undefined"
                      :value="recuperarExercicioPeloId(exercise.exercise._id)"
                      label="Exercício"
                      single-line
                      ref="descExercicio"
                      data-cy="exercicios"
                      style="padding-left:10px"
                    ></v-combobox>
                  </v-flex>
                  <v-flex xs12 sm4 md2>
                    <v-text-field data-cy="reps" v-model="exercise.reps" label="Reps" style="padding-left:10px"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <v-text-field data-cy="series" v-model="exercise.series" label="Séries" style="padding-left:10px"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md1>
                    <v-text-field data-cy="ordem" v-model="exercise.order" label="Ordem" style="padding-left:10px"></v-text-field>
                  </v-flex>
                    <v-icon
                      style="padding: 10px 10px"
                      small
                      title="Novo Exercício"
                      @click="addExercise"
                      data-cy="btn-add-exerc"
                    >
                      fa-plus-circle
                    </v-icon>
                    <v-icon
                      small
                      title="Remover"
                      @click="removerExercicio(index)"
                      data-cy="btn-remover-exerc"
                    >
                      fa-trash
                    </v-icon>
                </v-layout>
                <v-dialog
                  ref="dialog"
                  v-model="modalDate"
                  :return-value.sync="date"
                  width="290px"
                >
                  <v-date-picker v-model="date" scrollable locale="pt-br" no-title @input="setDate">
                  </v-date-picker>
                </v-dialog>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click.native="close">Cancelar</v-btn>
            <v-btn color="green darken-1" text @click.native="save">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model='dialogCopyWorkout' v-if="dialogCopyWorkout" max-width="700px">
        <v-card>
          <v-card-title>
            <span :style="{'font-size':'24px', 'font-weight':'500'}">Copiar treinos para usuários</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-flex xs12 sm12 md12>
                <v-combobox
                  :items="users"
                  :item-text="(user) => user.name"
                  :item-value="(user) => user.id"
                  v-model="selectedUsersToCopy"
                  multiple
                  chips
                  data-cy="usuarios-copiar"
                ></v-combobox>
              </v-flex>
            </v-container>
          </v-card-text>

          <v-card-actions :style="{'justify-content': 'flex-end'}">
            <v-btn data-cy="btn-cancelar" color="red darken-1" text @click.native="close">Cancelar</v-btn>
            <v-btn data-cy="btn-salvar" color="green darken-1" text @click.native="copySelectedWorkoutsToUsers(selectedUsersToCopy)">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTime"
        :color="snackbarType"
        :top="true"
      >
        {{snackbarMessage}}
      </v-snackbar>
  </div>

</template>

<script>
import ExerciseService from '@/services/ExerciseService';
import SportService from '@/services/SportService';
import WorkoutService from '@/services/WorkoutService';
import UserService from '@/services/UserService';
import draggable from 'vuedraggable';
const moment = require('moment');

export default {
  components: { draggable },
  data: () => ({
    userQuery: undefined,
    dialog: false,
    dialogConfirmacao: false,
    snackbar: false,
    snackbarMessage: '',
    snackbarType: '',
    snackbarTime: 3000,
    dialogCopyWorkout: false,
    workoutCrudErrorMessage: undefined,
    workoutsEx: [],
    workouts: [],
    exercises: [],
    sports: [],
    users: [],
    showExercise: false,
    editedIndex: -1,
    checkedWorkouts: [],
    selectedUsersToCopy: [],
    typeSelectedWorkout: undefined,
    editedItem: {},
    labels: [],
    values: [],
    inputStartDate: undefined,
    inputEndDate: undefined,
    date: new Date().toISOString().substr(0, 10),
    startDate: undefined,
    endDate: undefined,
    editingStartDate: true,
    showSparkline: false,
    modalDate: false,
    defaultWorkout: {
      name: '',
      description: '',
      status: 'active'
    },
    defaultItem: {
      reps: '',
      series: '',
      order: 1,
      exercise: {}
    },
    fab: false
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Treino' : 'Editar Treino';
    },
    computedDateStart () {
      return this.inputStartDate ? moment(this.inputStartDate).format('DD/MM/YYYY') : '';
    }
  },

  created() {
    this.$root.exibirBarraTopo = true;
    if (this.$route.query.user) {
      let self = this;
      let userId = this.$route.query.user;
      UserService.index({_id: userId}).then(function(dataUser) {
        self.userQuery = dataUser.data[0];
        self.initialize();
      });
    } else {
      this.initialize();
    }
  },

  watch: {
    dialog(val) {
      if (val === false) {
        this.close();
      }
    }
  },

  methods: {
    async initialize () {
      this.workoutCrudErrorMessage = undefined;
      let self = this;
      self.labels = [];
      self.values = [];
      self.sports = [];
      self.inputStartDate = undefined;
      await SportService.index().then(function(data) {
        let sports = data.data;
        for (let s of sports) {
          let itemComboBox = {
            text: s.name,
            value: s._id,
            typeWorkout: s.typeWorkout
          };
          self.sports.push(itemComboBox);
        }
      });
      ExerciseService.index().then(function(data) {
        let exercises = data.data;
        for (let ex of exercises) {
          let itemComboBox = {
            text: ex.name,
            value: ex._id
          };
          self.exercises.push(itemComboBox);
        }
      });
      WorkoutService.index(
        {
          status: 'active',
          user: self.userQuery ? self.userQuery._id : null
        }
      ).then(function(data) {
        self.workouts = [];
        for (let workout of data.data) {
          workout.exercises = self.orderedExercises(workout.exercises);
          self.workouts.push(workout);
        }
        self.workouts.forEach(workout => {
          self.sports.forEach(sport => {
            if (sport.value === workout.sport) {
              Object.assign(workout, {modalidade: sport.text});
            }
          });
        });
      });
      if (self.userQuery !== undefined) {
        WorkoutService.getCheckinsByUserAndDaysAgo(self.userQuery._id, 14).then(function(data) {
          var weekActualNumber = moment(new Date()).isoWeek();
          let checkins = data.data;
          if (checkins && checkins.length > 1) {
            let first = weekActualNumber - 7;
            let totalSemanas = 0;
            for (let index = first; index <= weekActualNumber; index++) {
              let achou = false;
              for (let ck of checkins) {
                if (ck._id === index) {
                  self.labels.push(ck.count);
                  self.values.push(ck.count);
                  achou = true;
                  totalSemanas++;
                }
              }
              if (!achou) {
                self.labels.push(0);
                self.values.push(0);
                totalSemanas++;
              }
            }
            if (totalSemanas < 7) {
              while (totalSemanas < 7) {
                self.values.push(0);
                totalSemanas++;
              }
            }
            self.showSparkline = true;
          }
        });
      }
    },
    formatarData: function(dateStr) {
      if (dateStr) {
        return moment(dateStr).format('DD/MM/YYYY');
      }
    },
    inputDate(isStartDate) {
      if (!this.dataPicker) {
        this.date = this.inputEndDate ? moment(this.inputEndDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
        if (isStartDate) {
          this.date = this.inputStartDate ? moment(this.inputStartDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
          this.startDate = this.date;
          this.editedItem.workoutDate = this.startDate;
        } else {
          this.endDate = this.date;
        }
      }
      this.dataPicker = false;
    },
    getDate: function(isStartDate) {
      this.editingStartDate = false;
      this.date = this.endDate || new Date().toISOString().substr(0, 10);
      if (isStartDate) {
        this.editingStartDate = true;
        this.date = this.startDate || new Date().toISOString().substr(0, 10);
      }
      this.modalDate = true;
    },
    setDate: function() {
      this.dataPicker = true;
      if (this.editingStartDate) {
        this.startDate = this.date;
        this.inputStartDate = moment(this.startDate).format('DD/MM/YYYY');
        this.editedItem.workoutDate = this.date;
      } else {
        this.endDate = this.date;
        this.inputEndDate = moment(this.endDate).format('DD/MM/YYYY');
      }
      this.modalDate = false;
      this.dataPicker = false;
    },
    getTypeWorkout(sport) {
      for (let s of this.sports) {
        if (s.value === sport) {
          return s.typeWorkout;
        }
      }
    },
    showExercises(val) {
      this.showExercise = false;
      this.editedItem.exercises = [];
      this.sports.forEach(s => {
        if (s.value === val) {
          if (s.typeWorkout === 'coletivo') {
            this.editedItem.exercises = [];
            this.showExercise = false;
          } else {
            this.inputStartDate = undefined;
            this.addExercise();
            this.showExercise = true;
          }
        }
      })
    },
    focusName() {
      setTimeout(() => {
        this.$refs.nomeWorkout.focus();
      }, 1000);
    },
    orderedExercises: function(exercises) {
      exercises = exercises.sort(function(a, b) {
        return a.order < b.order ? -1 : 1;
      });
      return exercises;
    },

    addWorkout: function() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultWorkout);
      if (this.userQuery && this.userQuery.id) {
        this.editedItem.user = this.userQuery._id;
        this.addExercise();
        this.showExercise = true;
      }
      this.dialog = true;
      setTimeout(() => {
        this.$refs.nomeWorkout.focus();
      }, 100);
    },

    editItem(item) {
      // this.showExercise = item.typeWorkout === 'personalizado';
      this.workoutCrudErrorMessage = undefined;
      this.editedIndex = this.workouts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.inputStartDate = this.formatarData(item.workoutDate);
      this.dialog = true;
    },

    addExercise() {
      this.workoutCrudErrorMessage = undefined;
      if (
        !this.editedItem.exercises ||
        this.editedItem.exercises.length === 0
      ) {
        this.editedItem.exercises = [];
      }
      this.editedItem.exercises.push(
        Object.assign({}, this.defaultItem)
      );
      let arrLength = this.editedItem.exercises.length;
      if (arrLength > 2) {
        let lastItem = this.editedItem.exercises[arrLength - 2];
        let lastButOne = this.editedItem.exercises[arrLength - 3];
        if (lastItem.reps === lastButOne.reps) {
          this.editedItem.exercises[arrLength - 1].reps = lastItem.reps;
        }
      }
      if (arrLength > 1) {
        let lastItem = this.editedItem.exercises[arrLength - 2];
        this.editedItem.exercises[arrLength - 1].order = Number(lastItem.order || 0) + 1;
      }
      let posicao = this.editedItem.exercises.length - 1;
      if (this.editedIndex !== -1) {
        this.$nextTick(function () {
          this.$refs.descExercicio[posicao].focus();
        });
      } else {
        this.$nextTick(function () {
          this.$refs.descExercicio[posicao].focus();
        });
      }
      this.defaultItem = {
        reps: '',
        series: '',
        order: 1,
        exercise: {}
      };
    },

    checkWorkout: function(val, workout) {
      if (val) {
        this.checkedWorkouts.push(workout._id);
        this.typeSelectedWorkout = this.getTypeWorkout(workout.sport);
      } else {
        let idx = this.checkedWorkouts.indexOf(workout._id);
        this.checkedWorkouts.splice(idx, 1);
      }
    },

    validateSelectedWorkouts: async function() {
      if (this.checkedWorkouts.length === 0) {
        this.snackbar = true;
        this.snackbarMessage = 'Selecione o(s) treino(s) a serem excluídos.';
        this.snackbarType = 'warning';
        this.snackbarTime = 4000;
        this.dialogConfirmacao = false;
      } else {
        this.dialogConfirmacao = true;
      }
    },

    deleteSelectedWorkouts: async function() {
      let selectedWorkouts = this.checkedWorkouts;
      for (let workout of this.workouts) {
        if (selectedWorkouts.indexOf(workout._id) > -1) {
          await WorkoutService.delete(workout);
        }
      }
      this.initialize();
      this.checkedWorkouts = [];
      this.dialogConfirmacao = false;
      this.snackbar = true;
      this.snackbarMessage = 'Treino(s) excluído(s) com sucesso!';
      this.snackbarType = 'success';
    },

    copyWorkoutsToUsers: function() {
      if (this.checkedWorkouts.length === 0) {
        this.snackbar = true;
        this.snackbarMessage = 'Selecione o(s) treino(s) a serem copiados.';
        this.snackbarType = 'warning';
        this.snackbarTime = 4000;
        this.typeSelectedWorkout = undefined;
        return;
      }
      console.log(this.typeSelectedWorkout);
      if (this.typeSelectedWorkout === 'coletivo') {
        this.snackbar = true;
        this.snackbarMessage = 'Não é possível copiar esse tipo de treino. Apenas treinos personalizados podem ser copiados!';
        this.snackbarType = 'warning';
        this.snackbarTime = 4000;
        this.typeSelectedWorkout = undefined;
        return;
      }
      let self = this;
      UserService.index().then(function(data) {
        self.users = data.data;
        self.dialogCopyWorkout = true;
      });
    },

    copySelectedWorkoutsToUsers: async function(users) {
      for (let workout of this.workouts) {
        if (this.checkedWorkouts.indexOf(workout._id) > -1) {
          workout._id = undefined;
          for (let user of users) {
            workout.user = user._id;
            workout.executions = undefined;
            workout.lastExecution = undefined;
            await WorkoutService.post(workout);
          }
        }
      }
      this.snackbar = true;
      this.snackbarMessage = 'Treino copiado com sucesso!';
      this.snackbarType = 'success';
      this.dialogCopyWorkout = false;
    },

    recuperarExercicioPeloId: function(exerciseId) {
      if (exerciseId) {
        let exercicio = this.exercises.filter(ex => ex.value === exerciseId)[0];
        return exercicio;
      }
      return undefined;
    },

    calculateRowSpan(index, workoutExercises) {
      let wExercise = workoutExercises[index];
      let rowSpan = 0;
      for (let idx in workoutExercises) {
        if (idx !== index) {
          let exerciseLoop = workoutExercises[idx];
          if (exerciseLoop.order && wExercise.order && exerciseLoop.order === wExercise.order) {
            rowSpan++;
          }
        }
      }
      return rowSpan;
    },

    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.workoutCrudErrorMessage = undefined;
      this.dialogCopyWorkout = false;
      this.editedIndex = -1;
      this.dialog = false;
      this.initialize();
    },

    validate(workout) {
      let message = '';
      let workoutMessage = '';
      if (!workout.name) {
        message += 'Informe um nome para o treino. \n';
      }
      if (this.getTypeWorkout(this.editedItem.sport) === 'personal') {
        if (workout.exercises === undefined || !workout.exercises || workout.exercises.length === 0) {
          message += 'Insira pelo menos um exercício no treino. \n';
        } else {
          workout.exercises.forEach(ex => {
            if (ex.exercise._id === undefined) {
              message = 'Informe o nome para todos os exercícios. \n';
            }
            if (ex.reps === undefined || ex.reps === '') {
              message = 'Informe a quantidade de repetições para todos os exercícios. \n';
            }
            if (ex.series === undefined || ex.series === '') {
              message = 'Informe as séries para todos os exercícios. \n';
            }
            if (ex.order === undefined || ex.order === '') {
              message += 'Informe a ordem para todos os exercícios. \n';
            }
            if (ex.order.toString() === '0') {
              message += 'Informe um valor maior que 0 para o campo Ordem. \n';
            }
          });
        }
      } else {
        if (workout.sport === undefined || workout.sport === '') {
          workoutMessage = 'Selecione uma modalidade!';
        }
        if (workout.workoutDate === undefined || workout.workoutDate === '') {
          workoutMessage = 'Informe uma data para o treino.';
        } else {
          this.workouts.forEach(work => {
            if (this.getTypeWorkout(work.sport) === 'coletivo' && workout.workoutDate.substr(0, 10) === work.workoutDate.substr(0, 10) && workout.sport === work.sport) {
              workoutMessage = 'Já existe um treino cadastrado para essa data e modalidade.';
            }
          });
        }
      }
      return message + workoutMessage;
    },

    cleanBeforeSave() {
      for (let ex of this.editedItem.exercises) {
        if (typeof ex.exercise !== 'string') {
          ex.exercise = ex.exercise._id;
        }
      }
    },
    formatToShow: function(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    save() {
      // this.editedItem.workoutDate = this.date !== undefined ? this.date : '';
      this.workoutCrudErrorMessage = undefined;
      let errorMessage = this.validate(this.editedItem);
      let messageSuccess = '';
      if (errorMessage) {
        this.snackbar = true;
        this.snackbarMessage = errorMessage;
        this.snackbarType = 'warning';
        this.snackbarTime = 4000;
        return;
      }
      let self = this;
      if (this.editedIndex > -1) {
        WorkoutService.put(this.editedItem).then(function() {
          // self.initialize();
        });
        messageSuccess = 'Treino alterado com sucesso!';
      } else {
        this.editedItem.order = this.workouts.length + 1;
        WorkoutService.post(this.editedItem).then(function() {
          // self.initialize();
        });
        messageSuccess = 'Treino cadastrado com sucesso!';
      }
      self.close();
      self.snackbar = true;
      self.snackbarMessage = messageSuccess;
      self.snackbarType = 'success';
    },
    removerExercicio(index) {
      if (this.editedItem.exercises.length > 1) {
        this.editedItem.exercises.splice(index, 1);
      } else {
        let posicao = this.editedItem.exercises.length - 1;
        this.$nextTick(function () {
          this.$refs.descExercicio[posicao].focus();
        });
        let errorMessage = this.validate(this.editedItem.exercises);
        if (errorMessage) {
          this.workoutCrudErrorMessage = errorMessage;
        }
      }
    },
    updateOrder() {
      for (let i = 0; i < this.workouts.length; i++) {
        this.workouts[i].order = i + 1;
        WorkoutService.put(this.workouts[i]);
      }
      this.snackbar = true;
      this.snackbarMessage = 'Treinos reordenados com sucesso!';
      this.snackbarType = 'success';
      this.snackbarTime = 1500;
    }
  }
};
</script>

<style>
  .header-treinos {
     background: linear-gradient(to right, #1d4291, #52adeb);
  }
</style>
<style>
