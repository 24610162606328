<template>
  <div>
    <v-layout row wrap justify-center :style="{'background-color': 'white', 'border-bottom': '2px solid rgba(0,0,0,.12)', 'margin-left':'0px', 'margin-right':'0px'}" >
      <div style="font-size:20px; align-self:center; margin-left:10px">Planos</div>
      <v-divider
        class="mx-2"
        inset
        vertical
        ></v-divider>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
       <v-text-field
          :class="classTextField"
          v-model="search"
          append-icon="fa-search"
          label="Localizar"
          single-line
          hide-details
          data-cy="localizar-exercicios"
        ></v-text-field>
      <v-dialog v-model='dialog' max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn data-cy="novo-exercicio" raised class="mb-2" color="primary" v-bind="attrs" v-on="on" style="margin-top: 10px">Cadastrar Plano</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span :style="{'font-size':'24px', 'font-weight':'500'}">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs10 sm10 md10>
                  <v-select
                    label="Modalidade"
                    :items="sports"
                    v-model="editedItem.sport"
                  ></v-select>
                </v-flex>
                <v-flex xs2 sm2 md2>
                  <v-checkbox
                    v-model="editedItem.inactive"
                    label="Inativo"
                    color="primary"
                    hide-details
                    @change="(val) => editedItem.inactive === val"
                    :style="{'margin-left': '30px', 'max-inline-size': 'fit-content', 'margin-bottom': '20px'}"
                    data-cy="inativar"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    filled
                    label="Descrição"
                    auto-grow
                    v-model="editedItem.description"
                    data-cy="descricao"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model='editedItem.validDays' label="Dias de vigência"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model='editedItem.daysWeek' label="Dias por semana"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field-money
                    v-model='editedItem.amount'
                    v-bind:label="Valor"
                    v-bind:properties="{
                      prefix: 'R$',
                      readonly: false,
                      disabled: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }"
                    v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }"
                  >
                  </v-text-field-money>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click.native="close">Cancelar</v-btn>
            <v-btn color="green darken-1" text @click.native="save">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTime"
      :color="snackbarType"
      :top="true"
    >
      {{snackbarMessage}}
    </v-snackbar>
    <v-data-table
      :headers='$vuetify.breakpoint.smAndDown ? headersMobile : headers'
      :items='plans'
      class="elevation-1"
      :search='search'
      :options.sync="pagination"
      :footer-props="{
        'items-per-page-text': 'Registros por página'
      }"
      data-cy="lista-exercicios"
    >
      <template v-slot:[`item.inactive`]="{ item }">
        <v-icon :style="{ 'fontSize': '14px', 'color': !item.inactive ? 'green' : 'red' }">fa-circle</v-icon>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <td class="justify-center layout px-0">
          <v-icon
            small
            class="mr-2"
            title="Editar"
            @click="editItem(item)"
            data-cy="editar-exerc"
          >
            fa-edit
          </v-icon>
        </td>
      </template>
      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SportService from '@/services/SportService';
import CustomerPlansService from '@/services/CustomerPlansService';

export default {
  data: () => ({
    classTextField: 'textfield',
    search: '',
    dialog: false,
    dialogConfirmacao: false,
    snackbar: false,
    snackbarMessage: '',
    snackbarType: '',
    snackbarTime: 3000,
    headers: [
      {
        text: 'Modalidade',
        align: 'center',
        value: 'modalidade'
      },
      {
        text: 'Descrição',
        align: 'center',
        value: 'description'
      },
      {
        text: 'Vigência',
        align: 'center',
        value: 'validDays'
      },
      {
        text: 'Dias na semana',
        align: 'center',
        value: 'daysWeek'
      },
      {
        text: 'Situação do plano',
        align: 'center',
        value: 'inactive'
      },
      { text: 'Ações', value: 'action', sortable: false, align: 'center' }
    ],
    headersMobile: [
      {
        text: 'Modalidade',
        align: 'center',
        value: 'modalidade'
      },
      {
        text: 'Descrição',
        align: 'center',
        value: 'description'
      },
      {
        text: 'Situação do plano',
        align: 'center',
        value: 'inactive'
      },
      { text: 'Ações', value: 'action', sortable: false, align: 'center' }
    ],
    sports: [],
    plans: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    pagination: {
      rowsPerPage: 10
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nova Modalidade' : 'Editar Modalidade';
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.$root.exibirBarraTopo = true;
    this.initialize();
  },

  methods: {
    async initialize() {
      let self = this;
      await SportService.index().then(function(data) {
        let sports = data.data;
        for (let s of sports) {
          let itemComboBox = {
            text: s.name,
            value: s._id
          };
          self.sports.push(itemComboBox);
        }
      });
      await CustomerPlansService.index().then(function(data) {
        self.plans = data.data;
        self.plans.forEach(plain => {
          self.sports.forEach(sport => {
            if (sport.value === plain.sport) {
              Object.assign(plain, {modalidade: sport.text});
            }
          });
        });
      });
    },

    editItem(item) {
      this.editedIndex = this.plans.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      let self = this;
      if (this.editedIndex > -1) {
        CustomerPlansService.put(self.editedItem).then(function() {
          self.initialize();
          self.snackbar = true;
          self.snackbarMessage = `Plano alterado com sucesso!`;
          self.snackbarType = 'success';
        });
      } else {
        CustomerPlansService.post(self.editedItem).then(function() {
          self.initialize();
          self.snackbar = true;
          self.snackbarMessage = `Plano cadastrado com sucesso!`;
          self.snackbarType = 'success';
        });
      }
      this.close();
    }
  }
};
</script>

<style>
  .textfield {
    padding-right: 30px;
    padding-bottom: 5px;
  }
  @media (max-width: 600px) {
    .textfield {
      padding-right: 0px;
      padding-bottom: 5px;
    }
  }

</style>
