<template>
  <v-layout wrap class="checkin-page">
    <v-flex xs12 sm12 md6 offset-md3>
      <v-card>
        <v-tabs
          v-model="tab"
          fixed-tabs
          style="padding-top: 20px"
        >
          <v-tabs-slider color="blue"></v-tabs-slider>
          <v-tab
            v-for="(tab, i) in plansTabs" :key="i"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
        <v-layout >
          <v-flex xs12 sm12 md6 style="text-align: -webkit-right; padding-top: 30px">
            <v-text-field
              ref="inputStartDate"
              label="Selecione..."
              v-mask="'##/##/####'"
              append-icon="fa-calendar"
              @click:append="getDate"
              @blur="inputDate"
              data-cy="data-inicial"
              :style="{'width':'200px', 'padding-left':'30px'}"
              v-model="inputStartDate"
              :value="computedDateStart"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md6 style="text-align: -webkit-left">
            <v-btn
              style="margin-left: 20px; margin-top: 40px"
              dark
              color="primary"
              data-cy="btn-changePass"
              @click="initialize">
              Buscar
            </v-btn>
          </v-flex>
        </v-layout>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(tab, i) in plansTabs" :key="i"
          >
            <v-divider></v-divider>
            <v-card-title primary-title style="margin-top: 10px; font-size:20px; justify-content: space-around">
              Treino do dia
            </v-card-title>
            <div :style="{'margin-top':'20px', 'padding-bottom':'20px', 'font-size':'18px', 'color':'grey', 'font-weight':'300', 'white-space':'pre'}" class="mb-0">{{tab.workout ? tab.workout.description : 'Nenhum treino cadastrado para essa data.'}}</div>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table
                v-if="tab.expired === false"
                :headers='$vuetify.breakpoint.smAndDown ? headersMobile : headers'
                :items='tab.classes'
                class="elevation-1"
                :hide-default-header='$vuetify.breakpoint.smAndDown ? true : false'
                :footer-props="{
                  'items-per-page-text': 'Registros por página'
                }"
                data-cy="lista-exercicios"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <td class="justify-center layout px-0">
                    <v-btn
                      elevation="2"
                      icon
                      medium
                      tile
                      title="Ver checkins"
                      style="margin-right: 10px; min-width:0px; color:blue"
                      @click="listUsersCheckin(item)"
                    >
                      <v-icon style="font-size: 12px">fa-users</v-icon>
                    </v-btn>
                    <v-btn
                      elevation="2"
                      icon
                      medium
                      tile
                      title="Realizar checkin"
                      style="margin-right: 10px; min-width:0px; color:green"
                      @click="classCheckin(item)"
                    >
                      <v-icon style="font-size: 12px">fa-check</v-icon>
                    </v-btn>
                    <v-btn
                      elevation="2"
                      icon
                      medium
                      tile
                      title="Cancelar checkin"
                      style="min-width:0px; color:red"
                      @click="dialogCancelCheckin = true; classeCheckin = item"
                    >
                      <v-icon style="font-size: 12px">fa-times</v-icon>
                    </v-btn>
                  </td>
                </template>
                <template v-slot:no-data>
                  <v-alert :value="true" color="grey lighten-3" icon="far fa-sad-tear">
                    Nenhum treino cadastrado para o dia de hoje! :(
                  </v-alert>
                </template>
              </v-data-table>
              <div v-else :style="{'margin-top':'20px', 'padding-bottom':'20px', 'font-size':'18px', 'color':'red', 'font-weight':'300', 'white-space':'pre'}" class="mb-0">Seu plano expirou. Entre em contato conosco!</div>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogUserCheckins" max-width="350">
      <v-card>
        <v-card-title :style="{'font-size':'20px'}">Checkins</v-card-title>
        <v-card-text>
          <v-data-table
            :items='classUsersCheckin'
            class="elevation-1"
            :hide-default-footer="true"
          >
            <template v-slot:[`item`]="{ item }">
              <tr :style="{'font-size':'25px'}">
                {{item}}
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="flex-direction: column">
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="dialogUserCheckins = false; classUsersCheckin = [];">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCheckin" max-width="350">
      <v-card>
        <v-card-title :style="{'font-size':'20px', 'font-weight':'500'}">Atenção</v-card-title>
        Confirma a realização do checkin?
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialogCheckin = false; initialize">Cancelar</v-btn>
          <v-btn color="success" text @click="doesCheckin">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancelCheckin" max-width="350">
      <v-card>
        <v-card-title :style="{'font-size':'20px', 'font-weight':'500'}">Atenção</v-card-title>
        Confirma o cancelamento do checkin?
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialogCancelCheckin = false">Cancelar</v-btn>
          <v-btn color="success" text @click="cancelCheckin">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="date"
      width="290px"
    >
      <v-date-picker v-model="date" scrollable locale="pt-br" no-title @input="setDate">
      </v-date-picker>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTime"
      :color="snackbarType"
      :top="true"
      :auto-height="true"
      :vertical="true"
    >
      {{snackbarMessage}}
    </v-snackbar>
  </v-layout>
</template>

<script>
import ClassesService from '@/services/ClassScheduleService';
import WorkoutService from '@/services/WorkoutService';
import UserService from '@/services/UserService';
import SportService from '@/services/SportService';
import CustomerPlansService from '@/services/CustomerPlansService';
const moment = require('moment');

export default {
  data: () => ({
    snackbar: false,
    snackbarMessage: '',
    snackbarType: '',
    snackbarTime: 3000,
    dialogCheckin: false,
    dialogCancelCheckin: false,
    dialogUserCheckins: false,
    classUsersCheckin: [],
    workouts: [],
    classes: [],
    sportsUser: [],
    userPlans: [],
    users: [],
    dateSize: 'dateSize',
    workoutOfTheDay: [],
    classeCheckin: undefined,
    cantDoesCheckin: true,
    userCheckin: [],
    sortBy: 'period',
    sortDesc: false,
    inputStartDate: undefined,
    editingStartDate: true,
    startDate: undefined,
    dataPicker: false,
    modal: false,
    date: new Date().toISOString().substr(0, 10),
    editedItem: {},
    tab: null,
    plansTabs: [],
    headers: [
      {
        text: 'Hora',
        align: 'center',
        value: 'period'
      },
      {
        text: 'Descrição',
        align: 'center',
        value: 'description'
      },
      {
        text: 'Vagas',
        align: 'center',
        value: 'totalCheckins'
      },
      { text: 'Ações', value: 'action', sortable: false, align: 'center' }
    ],
    headersMobile: [
      {
        value: 'period'
      },
      {
        align: 'center',
        value: 'description'
      },
      {
        align: 'center',
        value: 'totalCheckins'
      },
      { value: 'action', sortable: false, align: 'center' }
    ]
  }),
  computed: {
    computedDateStart () {
      return this.inputStartDate ? moment(this.inputStartDate).format('DD/MM/YYYY') : '';
    }
  },
  created: function() {
    this.$root.exibirBarraTopo = true;
    this.inputStartDate = moment(this.date).format('DD/MM/YYYY');
    this.initialize();
  },
  methods: {
    async initialize() {
      this.workouts = [];
      this.classes = [];
      this.sportsUser = [];
      this.workoutOfTheDay = [];
      this.plansTabs = [];
      let self = this;
      await UserService.index().then(function(data) {
        self.users = data.data;
      });
      await this.getUserPlans();
      await WorkoutService.index().then(function(data) {
        self.workouts = data.data;
      });
      await this.setWorkouts();
      await this.listWorkoutSchedule();
      await this.getClassesOfTheDay();
      await this.listClassesOnTabs();
      // await this.getUsersCheckins();
    },
    async setWorkouts() {
      let workouts = [];
      this.sportsUser.forEach(s => {
        this.workouts.forEach(w => {
          if (s.sport === w.sport) {
            workouts.push(w);
          }
        });
      });
      this.workouts = workouts;
    },
    async getUserPlans() {
      let userPlans = [];
      let plans = [];
      let sportsUser = [];
      this.sportsUser = [];
      let self = this;
      await UserService.index({_id: this.$store.state.user._id}).then(function(data) {
        userPlans = data.data[0].plans;
      });
      await CustomerPlansService.index().then(function(data) {
        plans = data.data;
        if (userPlans.length > 0) {
          userPlans.forEach(userPlan => {
            plans.forEach(plan => {
              if (userPlan.plan === plan._id) {
                self.sportsUser.push({sport: plan.sport, expired: userPlan.expired ? userPlan.expired : false});
              }
            });
          });
        }
      });
      await SportService.index().then(function(data) {
        sportsUser = data.data;
        if (self.sportsUser.length > 0) {
          self.sportsUser.forEach(sport => {
            sportsUser.forEach(sportUser => {
              if (sportUser._id === sport.sport) {
                self.plansTabs.push(sportUser);
              }
            });
          });
        }
      });
    },
    async listWorkoutSchedule() {
      let self = this;
      this.workoutOfTheDay = [];
      this.date = this.inputStartDate ? moment(this.inputStartDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
      self.workouts.forEach(workout => {
        if (workout.workoutDate && workout.workoutDate.substr(0, 10) === self.date.substr(0, 10)) {
          this.sportsUser.forEach(sport => {
            if (sport.sport === workout.sport) {
              self.workoutOfTheDay.push(workout);
            }
          });
        }
      });
    },
    containsClass(classe, classes) {
      let i;
      for (i = 0; i < classes.length; i++) {
        if (classes[i]._id === classe) {
          return true;
        }
      }
      return false;
    },
    async getClassesOfTheDay() {
      let nameDay = this.getNameDay(moment(this.inputStartDate, 'DD-MM-YYYY').format());
      let self = this;
      if (self.workoutOfTheDay.length > 0) {
        let hasDay = false;
        for (let workout of this.workoutOfTheDay) {
          await ClassesService.index({sport: workout.sport}).then(async function(data) {
            for (let d of data.data) {
              if (d.startDate <= self.date && d.endDate >= self.date) {
                for (let day of d.weekDays) {
                  if (day === nameDay) {
                    hasDay = true;
                  }
                }
                if (hasDay && !self.containsClass(d._id, self.classes)) {
                  self.classes.push(d);
                }
              }
              hasDay = false;
            }
            for (let classe of self.classes) {
              let nameUsersCheckin = [];
              let period = `${classe.startTime ? classe.startTime : ''} - ${classe.endTime ? classe.endTime : ''}`;
              Object.assign(classe, {period: period});
              let totalCheckins = `${classe.usersCheckin.length}/${classe.vacancies}`;
              Object.assign(classe, {totalCheckins: totalCheckins});
              for (let checkin of classe.usersCheckin) {
                for (let user of self.users) {
                  if (checkin === user._id) {
                    nameUsersCheckin.push(user.name);
                  }
                }
              }
              Object.assign(classe, {nameUsers: nameUsersCheckin});
            }
          });
        }
      }
    },
    listUsersCheckin(item) {
      this.dialogUserCheckins = true;
      this.classUsersCheckin = [];
      for (let i of item.nameUsers) {
        this.classUsersCheckin.push(i);
      }
    },
    async listClassesOnTabs() {
      let self = this;
      self.plansTabs.forEach(sport => {
        let classes = [];
        self.classes.forEach(classe => {
          if (classe.sport === sport._id) {
            classes.push(classe);
          }
        });
        self.sportsUser.forEach(plan => {
          if (plan.sport === sport._id) {
            this.$set(sport, 'expired', plan.expired);
          }
        });
        this.$set(sport, 'classes', classes);
        self.workoutOfTheDay.forEach(work => {
          if (work.sport === sport._id) {
            Object.assign(sport, {workout: work});
          }
        });
      });
    },
    getNameDay(date) {
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let d = new Date(date);
      let dayName = days[d.getDay()];
      return dayName;
    },
    classCheckin(item) {
      this.cantDoesCheckin = false;
      let classe = this.classes[this.classes.indexOf(item)];
      if (classe.usersCheckin) {
        classe.usersCheckin.forEach(user => {
          if (user === this.$store.state.user._id) {
            this.snackbar = true;
            this.snackbarMessage = 'Você já realizou checkin nesse horário!';
            this.snackbarType = 'blue';
            this.cantDoesCheckin = true;
          } else if (classe.usersCheckin.length === classe.vacancies) {
            this.snackbar = true;
            this.snackbarMessage = 'Não há mais vagas nesse horário!';
            this.snackbarType = 'warning';
            this.cantDoesCheckin = true;
          }
        });
      }
      if (!this.cantDoesCheckin) {
        this.dialogCheckin = true;
        this.classeCheckin = item;
      }
    },
    async cancelCheckin() {
      let self = this;
      this.editedItem = Object.assign({}, this.classeCheckin);
      this.editedItem.usersCheckin.splice(this.$store.state.user._id, 1);
      await ClassesService.put(this.editedItem).then(function() {
        self.snackbar = true;
        self.snackbarMessage = 'Checkin cancelado!';
        self.snackbarType = 'success';
        self.dialogCancelCheckin = false;
        self.initialize();
      });
    },
    doesCheckin: async function() {
      this.editedItem = Object.assign({}, this.classeCheckin);
      this.editedItem.usersCheckin.push(this.$store.state.user._id);
      let self = this;
      await ClassesService.put(this.editedItem).then(function() {
        self.snackbar = true;
        self.snackbarMessage = 'Checkin realizado!';
        self.snackbarType = 'success';
        self.dialogCheckin = false;
        self.initialize();
      });
    },
    inputDate() {
      this.date = this.inputStartDate ? moment(this.inputStartDate, 'DD-MM-YYYY').format() : new Date().toISOString().substr(0, 11);
    },
    getDate: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.modal = true;
    },
    setDate: function() {
      this.dataPicker = true;
      this.startDate = this.date;
      this.inputStartDate = moment(this.startDate).format('DD/MM/YYYY');
      this.modal = false;
      this.dataPicker = false;
    }
  }
};
</script>

<style>

</style>
