<template>
  <div class="numeric-keypad">
    <div v-for="k in keys" :key="k.value" class="key" @click="$emit('selection', k.value)" :style="{ 'background-color': k.backgroundColor }">
      <p :style="{ 'color': k.color }"><v-icon :style="{ 'color': k.color, margin: '0 5px' }">{{ k.icon }}</v-icon>{{ k.label !== undefined ? k.label : k.value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    keys: [
      {
        value: 1,
        label: 1
      },
      {
        value: 2
      },
      {
        value: 3
      },
      {
        value: 4
      },
      {
        value: 5
      },
      {
        value: 6
      },
      {
        value: 7
      },
      {
        value: 8
      },
      {
        value: 9
      },
      {
        value: 'clear',
        icon: 'fas fa-backspace',
        label: '',
        backgroundColor: '#FB270F',
        color: '#FFF'
      },
      {
        value: 0
      },
      {
        value: 'erase',
        icon: 'fas fa-arrow-left',
        label: '',
        backgroundColor: '#FEC022',
        color: '#FFF'
      },
      {
        value: 'start',
        icon: 'fas fa-play-circle',
        label: 'INICIAR',
        backgroundColor: '#4DA055',
        color: '#FFF'
      }
    ]
  })
};
</script>

<style>
.numeric-keypad {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.numeric-keypad .key {
  align-items: center;
  flex-grow: 1;
  width: 32%;
  padding: 25px 0;
  /* border: 1px solid #424242; */
  /* border-radius: 3px; */
  margin: 1px;
  /* height: 100px; */
  background-color: #f8f8f8;
  color: #000;
  font-size: 22px;
}
.numeric-keypad .key p{
  margin: 0;
}
.numeric-keypad .key:hover {
  background-color: #eee;
}
.numeric-keypad .key:active {
  background-color: #e0e0e0;
}
</style>
