<template>
  <v-layout class="checkin-page">
    <v-flex xs12 sm12 md6 offset-md3>
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 :style="{'font-size':'20px', 'font-weight':'500'}" class="mb-0">Vamos treinar {{$store.state.user.name}}!</h3>
          </div>
        </v-card-title>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title :style="{'font-size':'20px', 'font-weight':'500'}" class="grey lighten-2" primary-title>{{ workout.name }}</v-card-title>
        <v-card-text>
          <h3 v-if="workout && workout.user">{{ workout.user.name }}</h3>

          Descrição do Treino: {{ workout.description }}
          <table style="width: 100%" v-if="workout.WorkoutExercises">
            <tr>
              <th align="left">Exercício</th>
              <th width="100">Reps</th>
              <th width="100">Séries</th>
            </tr>
            <tr v-for="(exercise, index) in workout.WorkoutExercises" :key="index">
              <td align="left">{{ recuperarNomeExercicio(exercise.ExerciseId) }}</td>
              <td>{{ exercise.reps }}</td>
              <td
                :rowspan="calculateRowSpan(index, workout.WorkoutExercises)"
                v-if="!workout.WorkoutExercises[index-1] || (workout.WorkoutExercises[index-1].order !== exercise.order)"
              >{{ exercise.series }}</td>
            </tr>
          </table>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false,  goHome()">Cancelar</v-btn>
          <v-btn color="primary" text @click="skipWorkout(false)">Pular</v-btn>
          <v-btn color="success" text @click="startWorkout">Iniciar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="skipDialog" max-width="290">
      <v-card>
        <v-card-title :style="{'font-size':'20px', 'font-weight':'500'}">Deseja pular o treino?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="skipDialog = false, goHome()">Cancelar</v-btn>
          <v-btn color="green darken-1" text @click="skipWorkout(true)">Pular</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTime"
      :color="snackbarType"
      :top="true"
      :auto-height="true"
      :vertical="true"
    >
      {{snackbarMessage}}
    </v-snackbar>
  </v-layout>
</template>

<script>
import WorkoutService from '@/services/WorkoutService';
import ExerciseService from '@/services/ExerciseService';
import UserService from '@/services/UserService';

export default {
  data: () => ({
    code: '',
    dialog: false,
    skipDialog: false,
    snackbar: false,
    snackbarMessage: '',
    snackbarType: '',
    snackbarTime: 3000,
    workout: {},
    exercises: []
  }),
  created: function() {
    this.$root.exibirBarraTopo = true;
    this.checkIn();
  },
  methods: {
    checkIn: async function() {
      let self = this;
      await UserService.index({_id: this.$store.state.user._id}).then(function(data) {
        self.code = data.data[0].id;
      });

      let exercisesReturn = await ExerciseService.index();
      let exercises = exercisesReturn.data;
      for (let ex of exercises) {
        let exercise = {
          text: ex.name,
          value: ex.id
        };
        this.exercises.push(exercise);
      }
      let workout = await WorkoutService.nextWorkoutForUser(this.code);
      if (workout.data === 0) {
        this.snackbar = true;
        this.snackbarMessage = 'Usuário inválido!';
        this.snackbarType = 'error';
      } else if (workout.data.length === 0) {
        this.snackbar = true;
        this.snackbarMessage = 'Usuário sem treino cadastrado!';
        this.snackbarType = 'info';
      } else if (workout.data[0].user.status === 'INACTIVE') {
        this.snackbar = true;
        this.snackbarMessage = 'O usuário informado está inativo. Verifique seu cadastro!';
        this.snackbarType = 'error';
        this.snackbarTime = 5000;
      } else {
        this.workout = workout.data[0];
        this.dialog = true;
      }
    },
    startWorkout: async function() {
      await WorkoutService.startWorkout(this.workout._id);
      this.snackbar = true;
      this.snackbarMessage = 'Treino iniciado!';
      this.snackbarType = 'success';
      this.dialog = false;
      this.code = '';
      setTimeout(() => {
        this.goHome();
      }, 1000);
    },
    skipWorkout: async function(confirmed) {
      if (!confirmed) {
        this.dialog = false;
        this.skipDialog = true;
      } else {
        await WorkoutService.skipWorkout(this.workout._id);
        this.checkIn();
        this.skipDialog = false;
      }
    },
    recuperarNomeExercicio: function(exerciseId) {
      if (exerciseId > -1) {
        let exercicio = this.exercises.filter(ex => ex.value === exerciseId)[0];
        return exercicio.text;
      } else {
        return undefined;
      }
    },
    calculateRowSpan(index, workoutExercises) {
      let wExercise = workoutExercises[index];
      let rowSpan = 0;
      for (let idx in workoutExercises) {
        if (idx !== index) {
          let exerciseLoop = workoutExercises[idx];
          if (
            exerciseLoop.order &&
            wExercise.order &&
            exerciseLoop.order === wExercise.order
          ) {
            rowSpan++;
          }
        }
      }
      return rowSpan;
    },
    goHome() {
      this.$router.push({ path: '/userhome' });
    }
  }
};
</script>

<style>
/* #app {
  background-image: url("https://cdn.hipwallpaper.com/i/0/38/3UyVTx.jpg")
} */

/* .checkin-page h3{
  color: #fff;
}
.checkin-page input{
  color: #fff;
} */

.pointer {
  cursor: pointer;
}

</style>
